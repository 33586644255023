import { ConSourceModule } from '../interfaces';

export const CONSTANTS = {
  maxEstimatedBudget: 1000000000,
  defaultLanguage: 'english',
  defaultLanguageId: '61c1a737afc00e4c9aad3aeb',
  role: {
    clientAdmin: 1,
    admin: 5,
    manager: 2,
    partner: 3,
    sponsor: 4,
    firmAdmin: 6,
    firmUser: 7 /*firm partner*/,
    firmManagement: 8,
    msp: 9
  },
  allUserRole: [1, 2, 3, 4, 6, 7, 8, 9],
  CF_PROFILE_ROLES: [6, 7, 8],
  CF_PROFILE_USER_ROLES: [7, 8],
  CF_PROFILE_PARTNER_AND_USER: [7, 8],
  CF_PROFILE_ADMIN_AND_MANAGER: [6, 8],
  CLIENT_USER_ROLES: [1, 2, 3, 4, 9],
  ALL_CLIENT_SUPER_ADMIN_ROLES: [1, 2, 3, 4, 9, 5],
  CLIENT_USER: [2, 3, 4, 9],
  TEAM_ROLES: {
    PROJECT_CONTRIBUTOR: 5,
  },
  MAX_NOTIFICATION_COUNT_IN_HEADER_LIST: 10,
  CONSOURCE_TEAM: 'Consource Team',
  currencySymbol: {
    dollar: '$',
  },
  DEMANDS: 'DEMANDS',
  PIPELINE_MANAGEMENT: 'PIPELINE_MANAGEMENT',
  DELETE_DIALOG_CONFIG: {
    disableClose: true,
    width: '450px',
    title: 'Delete',
    message: `Are you sure you want to delete the Document?`,
    acceptanceText: 'Delete'
  },
  projectStep: {
    demand: 1,
    rfp: 2,
    sourcing: 3,
    questionAndAns: 4,
    selection: 5,
    sow: 6,
    projectManagement: 7,
    performance: 8,
  },
  ProjectFastTrack: {
    sandbox: 0,
    rfp: 1,
    sow: 2,
    management: 3,
    performance: 4,
  },
  NoDataContent: {
    search: '',
    btnName: 'Add',
    isSmall: false,
    title: 'No Records',
    content: 'Sorry, there are no records to display',
    isActionNeed: false,
    isIconNeed: true,
  },
  meetingType: {
    briefing: 'Briefing',
    pitch: 'Pitch',
  },
  YoutubeUrlPattern: /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  DEFAULT_USER_PROFILE_IMAGE: 'https://consource-assets.s3.us-east-2.amazonaws.com/assets/default_user_image.jpeg',
  tableType: {
    user: 'User',
    firm: 'Firm',
    client: 'Client',
    action: 'Action',
    firmAction: 'Firm Action',
    project: 'Project',
    country: 'Country',
    contact: 'Contact',
    contract: 'Contract',
    partner: 'Partner',
    projectManagementChanges: 'ProjectManagementChanges',
    sowDocument: 'SowDocument',
    sowHomeDocument: 'SowHomeDocument',
    pmObjectives: 'pmObjectives',
    projectRequest: 'Demand',
    invitedProject: 'Invited Project',
    rfpProposal: 'RfpProjectProposal',
    rfpExportHistory: 'RfpExportHistory',
    signatureExportHistory: 'SignatureExportHistory',
    rfpSowTeamMembers: 'RfpSowTeamMembers',
    rfpDemandApprover: 'RfpDemandApprover',
    rfpProposalClient: 'RfpProposalClient',
    organisationalUnit: 'OrganisationalUnit',
    projectPerformance: 'ProjectPerformance',
    strategicPriorities: 'Strategic Priorities',
    rfpProposalshortList: 'RfpProposalShortList',
    rfpProposalEvaluator: 'rfpProposalEvaluator',
    rfpProjectTeamMembers: 'RfpProjectTeamMembers',
    homeProjectTeamMembers: 'HomeProjectTeamMembers',
    sowTerminologyTermTable: 'SowTerminologyTermTable',
    rfpSelectionConsultingFirm: 'RfpSelectionConsultingFirm',
    RfpSelectionConsultingFirmHome: 'RfpSelectionConsultingFirmHome',
    rfpInvitationConsultingFirm: 'RfpInvitationConsultingFirm',
    sowProjectManagementMilestone: 'SowProjectManagementMilestone',
    pmMilestone: 'pmMilestone',
    changesManagementMilestone: 'changesManagementMilestone',
    sowProjectManagementGovernance: 'SowProjectManagementGovernance',
    sowProjecTermsAndConditionCost: 'SowProjecTermsAndConditionCost',
    projectPerformanceExpectedImpact: 'ProjectPerformanceExpectedImpact',
    sowProjecTermsAndConditionMileStone: 'SowProjecTermsAndConditionMileStone',
    sowProjecTermsAndConditionDetailedFee: 'sowProjecTermsAndConditionDetailedFee',
    library: 'Library',
    meetings: 'Meeting',
    dashboardMeeting: 'DashboardMeeting',
    ndaTerminologyTermTable: 'ndaTerminologyTermTable',
    definitionTable: 'ndaTerminologyTermTable',
    legalEntities: 'LegalEntities',
    invoicingAndPayment: 'invoicingAndPayment',
    legalEntity: 'legalEntity',
    linkedFirm: 'Linked Firm',
    workspaceMeetings: 'workspaceMeetings',
    performanceDocument: 'performanceDocument',
    performanceHomeTabDocument: 'performanceHomeTabDocument',
    rfpTeamsDisabled: 'rfpTeamsDisabled',
    suggestedProviders: 'suggestedProviders',
    pmChanges: 'pmChanges',
    cfClient: 'cfClient',
    cpProjects: 'clientProfileProjects',
    cpContracts: 'clientProfileContracts',
    cpMeetings: 'clientProfileMeetings',
    pmInvoice: 'pmInvoice',
    pmTableMilestone: 'pmTableMilestone',
    pmTableHome: 'pmTableHome',
    pmTableDocs: 'pmTableDocs'
  },
  workspaceTab: {
    file: 'file',
    meetings: 'meetings',
    task: 'task',
    links: 'links',
    chats: 'chats',
  },
  accessValue: {
    edit: ['edit', 'editAll'],
    delete: ['delete', 'deleteAll'],
    deleteOwn: ['delete'],
    view: ['view', 'viewAll'],
    create: ['create'],
    deleteAll: ['deleteAll'],
    editAll: ['editAll'],
    suggest: ['suggest'],
  },
  signatureTabs: {
    execution: 'execution',
    finalization: 'finalization',
    selection: 'selection',
    award: 'award',
    purchaseOrder: 'purchaseOrder',
  },
  pmChangesTabs: {
    tracing: 'tracing',
    preparation: 'preparation',
    execution: 'execution',
    admin: 'admin',
    introduction: 'introduction',
    objectives: 'objectives',
    milestones: 'milestones',
    invoicing: 'invoicing',
    otherChanges: 'otherChanges',
    finalization: 'finalization',
    selection: 'selection',
    layout: 'layout',
    history: 'history',
    exportHistory: 'exportHistory',
    previewPdf: 'previewPdf',
    exportPdf: 'exportPdf'
  },
  tracingSectionNames: {
    TRACING_MILESTONE: 'tracing-milestone',
    TRACING_OBJECTIVE: 'tracing-objective'
  },
  envelopStatus: {
    notSent: 'notSent',
    sent: 'sent',
    delivered: 'delivered',
    signed: 'signed'
  },
  contractType: {
    sow: 1,
    nda: 2,
    msa: 3,
  },
  proposalTableType: {
    teamScoring: 'teamMyScoring',
    myScoring: 'proposalMyScoring',
  },
  proposalEditTableType: {
    technical: 'Technical',
    commercial: 'Commercial'
  },
  pattern: {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    alphanumeric: /^[a-zA-Z0-9 ]*$/
  },
  threeMonths: '3_Months',
  sixMonths: '6_Months',
  twelveMonths: '12_Months',
  All: 'All',
  Custom: 'Custom',
  fiscalYear: 'fiscalYear',
  geographyTypes: [
    { name: 'Regions', value: 1 },
    { name: 'Countries', value: 2 },
    { name: 'Other', value: 3 },
  ],
  filterValue: [
    {
      id: 1,
      name: 'Results',
    },
    {
      id: 2,
      name: 'Process',
    },
    // {
    //   id: 5,
    //   name: 'Others',
    // },
  ],
  displayRoles: [
    {
      id: 2,
      name: 'Manager',
    },
    {
      id: 3,
      name: 'Partner',
    },
    /*{
            id: 4,
            name: 'Manager and Partner',
        },*/
  ],
  displayConsultingFirmRole: [
    {
      id: 8,
      name: 'User',
    },
    {
      id: 7,
      name: 'Management',
    },
  ],
  displayConsultingFirmChangeRole: [
    {
      id: 6,
      name: 'Admin',
    },
    {
      id: 7,
      name: 'Management',
    },
    {
      id: 8,
      name: 'User',
    },
  ],
  displayClientUserRoles: [
    {
      id: 2,
      name: 'Service Procurement',
    },
    {
      id: 3,
      name: 'Management',
    },
    {
      id: 4,
      name: 'User',
    },
  ],
  displayClientRoles: [
    {
      id: 1,
      name: 'Admin',
    },
    {
      id: 2,
      name: 'Services Procurement',
    },
    {
      id: 3,
      name: 'Management',
    },
    {
      id: 4,
      name: 'User',
    },
    {
      id: 9,
      name: 'MSP',
    },
  ],
  rfpProjectTeamRoles: [
    {
      id: 1,
      name: 'Leader',
    },
    {
      id: 2,
      name: 'Member',
    },
  ],
  teamMemberRoles: {
    Project_Lead: 1,
    Project_Team: 2,
    Legal_Team: 3,
  },
  projectSowTeamRoles: [
    {
      id: 1,
      name: 'Project Lead',
    },
    {
      id: 2,
      name: 'Team Member',
    },
  ],
  projectTeamRoles: [
    {
      id: 1,
      name: 'Project Lead',
    },
    {
      id: 2,
      name: 'Team Member',
    },
    {
      id: 3,
      name: 'Project Contributor',
    },
  ],
  clientPriority: [
    {
      id: 1,
      name: 'Commercial',
    },
    {
      id: 2,
      name: 'Delivery',
    },
    {
      id: 3,
      name: 'Talent Expertise',
    },
    {
      id: 4,
      name: 'Behavior',
    },
    {
      id: 5,
      name: 'Impact',
    },
  ],
  clientPriorityForSpendAnalysis: [
    {
      id: 1,
      name: 'Commercial',
    },
    {
      id: 2,
      name: 'Delivery',
    },
    {
      id: 3,
      name: 'Talent Expertise',
    },
    {
      id: 4,
      name: 'Behavior',
    },
    {
      id: 5,
      name: 'Impact',
    },
    {
      id: 6,
      name: 'Global',
    },
  ],
  projectType: [
    {
      id: 1,
      name: 'Long term',
    },
    {
      id: 2,
      name: 'Short term',
    },
    {
      id: 3,
      name: 'Retainer',
    },
    {
      id: 4,
      name: 'Workshop',
    },
  ],
  isProjectPaid: [
    {
      id: 1,
      name: 'Validated',
    },
    {
      id: 2,
      name: 'Engaged',
    },
  ],
  projectRequestStatus: [
    {
      id: 0,
      name: 'Requested',
    },
    {
      id: 1,
      name: 'Approved',
    },
    {
      id: 2,
      name: 'Rejected',
    },
    {
      id: 3,
      name: 'Drafted',
    },
  ],
  projectPerformanceType: [
    {
      id: 1,
      name: 'Projects',
    },
    {
      id: 2,
      name: 'Capabilities',
    },
    {
      id: 3,
      name: 'Industries',
    },
  ],
  statusFilterOptions: [
    {
      _id: 1,
      name: 'Active',
    },
    {
      _id: 0,
      name: 'Pending',
    },
  ],
  userStatusFilterOptions: [
    {
      _id: 1,
      name: 'Active',
    },
    {
      _id: 2,
      name: 'Pending',
    },
    {
      _id: 3,
      name: 'Inactive',
    },
  ],
  statusFilterOptionsForFirm: [
    {
      _id: 1,
      name: 'Active',
    },
    {
      _id: 2,
      name: 'Pending',
    },
    {
      _id: 3,
      name: 'Disabled',
    },
  ],
  roleFilterOptions: [
    {
      _id: 2,
      name: 'Manager',
    },
    {
      _id: 3,
      name: 'Partner',
    },
    /*{
            _id: 4,
            name: 'Manager and Partner',
        },*/
  ],
  clientRoleFilterOptions: [
    {
      _id: 2,
      name: 'Services Procurement',
    },
    {
      _id: 3,
      name: 'Management',
    },
    {
      _id: 4,
      name: 'User',
    },
    {
      _id: 9,
      name: 'MSP',
    },
  ],
  contactStatus: [
    {
      _id: 3,
      name: 'All',
    },
    {
      _id: 1,
      name: 'Pending',
    },
    {
      _id: 2,
      name: 'Completed',
    },
  ],
  admin: 'Admin',
  user: 'User',
  dateFilterOption: [
    {
      id: 1,
      name: 'Today',
    },
    {
      id: 2,
      name: 'Yesterday',
    },
    {
      id: 3,
      name: 'Last 7 days',
    },
    {
      id: 4,
      name: 'Last 30 days',
    },
    {
      id: 5,
      name: 'Last 90 days',
    },
  ],
  demandDateFilterOption: [
    {
      id: 1,
      name: 'Today',
    },
    {
      id: 2,
      name: 'Yesterday',
    },
    {
      id: 3,
      name: 'Last 7 days',
    },
    {
      id: 4,
      name: 'Last 30 days',
    },
    {
      id: 5,
      name: 'Last 90 days',
    },
    {
      id: 6,
      name: 'Custom Date Range',
    },
  ],
  filterOptionForProjectEndDate: [
    {
      id: 1,
      name: 'Today',
    },
    {
      id: 2,
      name: 'Yesterday',
    },
    {
      id: 3,
      name: 'Last 7 days',
    },
    {
      id: 4,
      name: 'Last 30 days',
    },
    {
      id: 5,
      name: 'Last 90 days',
    },
    {
      id: 10,
      name: 'Upcoming Days',
    },
  ],
  actionDateFilterOption: [
    {
      id: 7,
      name: 'Remote',
    },
    {
      id: 8,
      name: 'Upcoming',
    },
    {
      id: 9,
      name: 'Overdue',
    },
    {
      id: 6,
      name: 'Custom Date Range',
    },
  ],
  flagUrl: 'https://devapp.consource.io/assets/images/flags/',
  knowledgeBaseConsourceUrl: 'https://support.consource.io/portal/en/signin',
  surveyId: '5cac4a07394ad1c096d0cc5b',
  displayMonth: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  variabilityGraphXAxis: [
    {
      id: 1,
      name: 'Capability',
    },
    {
      id: 2,
      name: 'Purpose',
    },
  ],
  variabilityGraphYAxis: [
    {
      id: 1,
      name: 'Score',
    },
    {
      id: 2,
      name: 'Price',
    },
  ],
  profileGraphXAxis: [
    {
      id: 1,
      name: 'Projects',
    },
    {
      id: 2,
      name: 'Providers',
    },
  ],
  auth0Config: {
    domain: 'consourse-dev.us.auth0.com',
    clientId: 'ArVnI0yjXNbluqoMMSKiUGNevci6Ekyc',
    audience: 'https://consourse-dev.us.auth0.com/api/v2/'
  },
  totalDaysFreeTrail: 30,
  zohoCustomerPortalUrl: 'https://subscriptions.zoho.com/portal/consultingquest',
  revenueFilterType: [
    {
      id: 1,
      name: 'Projects',
    },
    {
      id: 2,
      name: 'Clients',
    },
    {
      id: 3,
      name: 'Capabilities',
    },
    {
      id: 4,
      name: 'Industries',
    },
  ],
  consourceRevenueFilterType: [
    {
      id: 1,
      name: 'Projects',
    },
    {
      id: 5,
      name: 'Providers',
    },
    {
      id: 3,
      name: 'Capabilities',
    },
    {
      id: 4,
      name: 'Industries',
    },
  ],
  benchmarkFilterType: [
    {
      id: 1,
      name: 'Partners',
    },
    {
      id: 2,
      name: 'Capabilities',
    },
    {
      id: 3,
      name: 'Industries',
    },
    {
      id: 4,
      name: 'Organisation Tags',
    },
  ],
  budgetOptions: [
    {
      key: 'no-condition',
      value: 'no-condition',
    },
    {
      key: '>',
      value: 'greater than',
    },
    {
      key: '<',
      value: 'lesser than',
    },
    {
      key: '>=',
      value: 'greater than or equal to',
    },
    {
      key: '<=',
      value: 'lesser than or equal to',
    },
    {
      key: 'between',
      value: 'between',
    },
  ],
  projectRequestTypes: [
    {
      key: 0,
      value: 'All',
    },
    {
      key: 1,
      value: 'Requests Sent',
    },
    {
      key: 2,
      value: 'Requests Received',
    },
  ],
  surveyStatus: {
    NotSend: 1,
    SentButNotCompleted: 2,
    Completed: 3,
  },
  projectRequestNotMatched: 'The Demand is not matched on any approval criteria',
  actionsNature: [
    {
      key: '0',
      value: 'Administrative',
    },
    {
      key: '1',
      value: 'Debriefing',
    },
    {
      key: '2',
      value: 'Improvement',
    },
    {
      key: '3',
      value: 'Opportunity',
    },
  ],
  meetingtypes: [
    {
      key: 0,
      value: 'All',
    },
    {
      key: 1,
      value: 'Project Management',
    },
    {
      key: 2,
      value: 'Provider',
    },
  ],
  taskActionNature: [
    {
      key: '4',
      value: 'Project',
    },
  ],
  actioinStatus: [
    {
      key: 0,
      value: 'Open',
    },
    {
      key: 1,
      value: 'Closed',
    },
  ],
  archiveStatus: [
    {
      key: 'Archived',
      value: 1,
    },
    {
      key: 'Non Archived',
      value: 0,
    },
  ],
  tierTypes: {
    project: 1,
    ConsultingFirm: 2,
  },
  tierCategory: {
    tier: 'tier',
    segment: 'segment',
  },
  rfpDemandDocument: {
    size: 10 * 1024 * 1024,
    allowedFiles: '.doc,.docx,.pptx,.xlxs,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  },
  rfpQuestionVisibility: [
    {
      key: -1,
      value: 'All',
    },
    {
      key: 1,
      value: 'Public',
    },
    {
      key: 2,
      value: 'Private',
    },
  ],
  filePath: {
    rfp: '',
    sowDocument: 'project/sow',
    proposalAndSelection: 'project/proposalAndSelection',
    milestone: 'project/projectManagementDocument',
    invoicing: 'projectManagementInvoicing',
    ndaDocument: 'contracts/nda',
    msaDocument: 'contracts/msa',
    onBoardingTemplate: 'on-boarding/template',
    chat: 'workspace/chat',
    workspace: 'workspace',
    changeOrder: 'project/change-order'
  },
  proposaAndSelectionType: [
    {
      key: 1,
      value: 'Proposal',
    },
    {
      key: 2,
      value: 'Side Document',
    },
  ],
  proposaAndSelectionStep: [
    {
      key: 1,
      value: 'Initial',
    },
    {
      key: 2,
      value: 'Pitch',
    },
    {
      key: 3,
      value: 'Revisions',
    },
  ],
  rfpProposalText: 'Reception of the Initial Proposals',
  rfpPitchByConsultants: 'Pitch by Providers',
  rfpFinalSelection: 'Final Selection',
  clientURL: {
    adminActionUrl: '/dashboard/tasks/all-tasks',
    actionUrl: '/dashboard/tasks/my-tasks',
  },
  superAdminUrl: {
    actionUrl: '/dashboard/my-tasks',
  },
  firmAccountUrl: {
    accountUrl: '/invitation-dashboard/account-info/accounts-billing/subscription', 
  },
  clientAccount: {
    accountUrl: '/dashboard/account-info/accounts-billing/subscription', 
  },
  legalEntityAccount: {
    accountUrl: 'invitation-dashboard/legal-entity/edit',
  },
  firmURL: {
    actionURL: '/invitation-dashboard/my-tasks',
  },
  SowRefDocument: [
    {
      key: 1,
      value: 'RFP',
    },
    {
      key: 2,
      value: 'Proposal',
    },
    {
      key: 3,
      value: 'Others',
    },
    {
      key: 4,
      value: 'NDA',
    },
    {
      key: 5,
      value: 'MSA',
    },
    {
      key: 6,
      value: 'SOW',
    },
  ],
  ViewSowRefDocument: [
    {
      key: 1,
      value: 'RFP',
    },
    {
      key: 2,
      value: 'Proposal',
    },
    {
      key: 3,
      value: 'Others',
    },
    {
      key: 4,
      value: 'NDA',
    },
    {
      key: 5,
      value: 'MSA',
    },
    {
      key: 6,
      value: 'SOW',
    },
    {
      key: 7,
      value: 'MSA',
    },
    {
      key: 8,
      value: 'MSA',
    },
  ],
  fastTrackList: [
    {
      key: 1,
      value: 'RFP',
    },
    {
      key: 2,
      value: 'SOW',
    },
    {
      key: 3,
      value: 'Project Management',
    },
    {
      key: 4,
      value: 'Performance',
    },
  ],
  DOCUMENT_TYPE: {
    LOCAL: 1,
    DRIVE: 2,
  },
  SPECIFIC_INFO_DOC_TYPE: {
    COMPLIANCE: 'complianceData',
    CERTIFICATION: 'certificationData',
    LEGAL: 'legalFiscalData',
    ONBOARDING: 'onboardingData',
  },
  FIRM_SPECIFIC_INFO_DOC_FILE_LIMIT: 3,
  CONTRACTSTATUS: {
    INPROGRESS: 'in-progress',
    EXECUTED: 'executed',
  },
  CONTRACT_TYPE_ID: {
    1: 'sowId',
    2: 'ndaId',
    3: 'msaId',
  },
  CONTRACT_TYPE_KEYS: {
    1: 'sowDetails',
    2: 'ndaDetails',
    3: 'msaDetails',
  },
  CONTRACT_DOCUMENT_PATH: {
    2: 'contracts/nda',
    3: 'contracts/msa',
  },
  GENERIC_NO_DATA_TABLE_ERROR_MESSAGE: {
    title: 'No Records',
    subtitle: `SORRY! There Isn't Any Record To Display!`,
  },
  CURRENCY_TYPES_IN_PROJECTS: {
    INVOICE_CURRENCY: 'Invoice Currency',
    PROJECT_CURRENCY: 'Project Currency',
    COMPANY_CURRENCY: 'Company Currency',
  },
  GENERIC_ERROR_RESPONSE: 'Something went wrong',

  UserRoles: {
    'Client Admin': 1,
    'Procurement Manager': 2, 
    'Project Manager': 3,
    'Project Sponsor': 4,
    'Provider Admin': 6,
    'Provider Parther': 7,
    'Provider User': 8,
    'Provider Management': 9,
  },

  ProjectStatus: {
    'ACTIVE': 'ACTIVE',
    'PAUSE': 'PAUSE',
    'CANCEL': 'CANCEL'
  }
};

export const DefaultContentStyle = { fontStyle: '', fontSize: '', fontFamily: '', color: '' };

export const ASSETS = {
  NO_DATA_FOUND_IMAGE_PATH: 'assets/not_found.png',
  COMING_SOON_IMAGE_PATH: 'assets/coming_soon.png',
  CARD_IMAGE_PATH: 'assets/card-image.png',
  AWARDS_IMAGE_PATH: 'assets/awards.png',
  DEFAULT_COMPANY_PROFILE_IMAGE: 'assets/company-profile-photo.png',
  WET_INK_SIGNATURE: 'assets/wet-ink.svg',
  E_SIGNATURE: 'assets/e-sign.svg',
  LINK_ICON: 'assets/link_icon.svg',
  MARKET_PLACE_ICON: 'assets/Marketplace.svg',
  NOTIFICATION: 'assets/notification-new.svg'
};

export const rfpDocumentAllowedFileTypes = ['.pdf', '.docx', '.doc', 'xlsx', '.xls', '.ppt', '.pptx']; // Common document file formats
export const rfpDocumentAllowedOverallSize = 50 * 1024 * 1024; // 50 MB
export const rfpDocumentAllowedFileCount = 2; // Max Files Count

export const rfpPdfLogoAllowedFileTypes = ['.jpeg', '.jpg', '.png']; // Image file formats
export const rfpPdfLogoAllowedSize = 50 * 1024; // 50 KB

export const defaultCompanyProfilePhoto = 'assets/images/company-profile-photo.png';

export const RfpQuillConfig = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', { color: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ list: 'ordered' }, { list: 'bullet' }, 'link'],
      ['image'],
    ],
  },
  imageResize: true,
};

export const RfpSettingsQuillConfig = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', { color: [] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['image'],
    ],
  },
  imageResize: true,
  textChanges: true,
};

export const RfpSettingsQuillConfigWithoutToolbar = {
  toolbar: false,
  imageResize: true,
};

export enum RfpTableTypes {
  'Demand',
  'RFP',
  'Project',
  'SOW',
}

export const LayoutSections = [
  {
    sectionName: '',
    sectionKey: 'brandingPreferences',
    fields: [
      {
        fieldName: 'Cover Page Content',
        fieldKey: 'coverPageContent',
      },
      {
        fieldName: 'Header Content',
        fieldKey: 'headerContent',
      },
      {
        fieldName: 'Footer Content',
        fieldKey: 'footerContent',
      },
    ],
  },
];

export const MsaDefaultSections = [
  {
    sectionName: 'Preliminary Info',
    sectionKey: 'preliminaryInfo',
    fields: [
      {
        fieldName: 'Introduction & Parties',
        fieldKey: 'introduction',
        placeHolder: 'Please provide details about the parties entering into this agreement.',
      },
      {
        fieldName: 'Recitals',
        fieldKey: 'recitals',
        placeHolder: 'Please specify the reasons for setting up this agreement',
      },
      {
        fieldName: 'Definitions',
        fieldKey: 'terminologyAndAcronyms',
        placeHolder: 'Please provide definitions for the terminology and acronyms used in this agreement',
      },
    ],
  },
  {
    sectionName: 'Term & Termination',
    sectionKey: 'termAndTermination',
    fields: [
      {
        fieldName: 'Effective Date',
        fieldKey: 'table',
      },
      {
        fieldName: 'Term',
        fieldKey: 'table',
      },
      {
        fieldName: 'Termination',
        fieldKey: 'termination',
        placeHolder: 'Please detail the principles for terminating this agreement',
      },
      {
        fieldName: 'Renewal Date',
        fieldKey: 'table',
      },
    ],
  },
  {
    sectionName: 'Services',
    sectionKey: 'services',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Standards of Performance',
    sectionKey: 'standardsOfPerformance',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Billing & Payment',
    sectionKey: 'billing',
    fields: [
      {
        fieldName: 'Fee Structure',
        fieldKey: 'feeStructure',
        placeHolder: 'Please provide the detail about fee structure',
      },
      {
        fieldName: 'Payment Terms',
        fieldKey: 'paymentTerm',
        placeHolder: 'Please provide the details about payment terms',
      },
      {
        fieldName: 'Invoicing procedures',
        fieldKey: 'invoicingProcedure',
        placeHolder: 'Please provide the detail about invoicing procedures',
      },
      {
        fieldName: 'Expenses and Reimbursement Policies',
        fieldKey: 'policies',
        placeHolder: 'Please provide the detail about expenses and reimbursement policies',
      },
      {
        fieldName: 'Taxes',
        fieldKey: 'taxes',
        placeHolder: 'Please provide the detail about taxes',
      },
      {
        fieldName: 'Rebates and Discounts',
        fieldKey: 'rebatesDiscounts',
        placeHolder: 'Please provide the detail about rebates and discounts',
      },
    ],
  },
  {
    sectionName: 'General Provisions',
    sectionKey: 'generalProvisions',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Other Provisions',
    sectionKey: 'otherProvisions',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Appendix',
    sectionKey: 'appendix',
    type: 'dynamic',
    fields: [],
  },
];

export const NdaDefaultSections = [
  {
    sectionName: 'Preliminary Info',
    sectionKey: 'preliminaryInfo',
    fields: [
      {
        fieldName: 'Introduction & Parties',
        fieldKey: 'intro',
        placeHolder: 'Please provide details about the parties entering into this agreement.',
      },
      {
        fieldName: 'Recitals',
        fieldKey: 'recitals',
        placeHolder: 'Please specify the reasons for setting up this agreement.',
      },
      {
        fieldName: 'Terminology',
        fieldKey: 'terminology',
        placeHolder: 'Please provide definitions for the terminology and acronyms used in this agreement.',
      },
    ],
  },
  {
    sectionName: 'Term & Termination',
    sectionKey: 'termsAndTermination',
    fields: [
      {
        fieldName: 'Effective Date',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the NDA creation.',
      },
      {
        fieldName: 'Term',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the NDA creation.',
      },
      {
        fieldName: 'Termination',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the NDA creation.',
      },
      {
        fieldName: 'Renewal Date',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the NDA creation.',
      },
    ],
  },
  {
    sectionName: 'Confidential information',
    sectionKey: 'confidentialInformation',
    fields: [
      {
        fieldName: 'Definition of Confidential Information',
        fieldKey: 'definition',
        placeHolder: 'Please provide details to define what is considered confidential information in this agreement.',
      },
      {
        fieldName: 'Exclusions from Confidential Information',
        fieldKey: 'exclusions',
        placeHolder: 'Please provide details to define what is excluded from the being considered confidential information in this agreement.',
      },
    ],
  },
  {
    sectionName: 'Obligations',
    sectionKey: 'obligations',
    fields: [
      {
        fieldName: 'Obligations of disclosing party',
        fieldKey: 'disclosingParty',
        placeHolder: 'Please detail the obligations of the disclosing party.',
      },
      {
        fieldName: 'Obligations of receiving party',
        fieldKey: 'receivingParty',
        placeHolder: 'Please detail the obligations of the receiving party.',
      },
    ],
  },
  {
    sectionName: 'General Provisions',
    sectionKey: 'generalProvisions',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Other Provisions',
    sectionKey: 'otherProvisions',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Execution',
    type: 'populatedInProject',
    placeHolder: 'This item is project specific and will be populated during the NDA creation.',
    fields: [],
  },
];

export const RFPDefaultSections = [
  {
    sectionName: 'Purpose',
    sectionKey: 'purpose',
    fields: [
      {
        fieldName: 'Purpose of the RFP',
        fieldKey: 'projectOverview',
        placeHolder: 'Please detail the purpose of the RFP here.',
      },
    ],
  },
  {
    sectionName: 'Background',
    sectionKey: 'background',
    fields: [
      {
        fieldName: 'About us',
        fieldKey: 'companyOverview',
        placeHolder: 'Please provide a short description of your company as well as any information you deem relevant',
      },
      {
        fieldName: 'Additional Information',
        fieldKey: 'level2Overview',
        placeHolder: 'Please provide if relevant additional background information about how your entity fits in with the overall company',
      },
      {
        fieldName: 'Strategic Background',
        fieldKey: 'strategicAmbition',
        placeHolder: 'Please describe here the strategic context in which the project will take place',
      },
    ],
  },
  {
    sectionName: 'Context',
    sectionKey: 'context',
    fields: [
      {
        fieldName: ' Rationale for the Project',
        fieldKey: 'rationale',
        placeHolder: 'Please provide the rationale that led the company to launch this project',
      },
      {
        fieldName: 'High Level Objectives',
        fieldKey: 'keyQuestions',
        placeHolder: 'Please describe the high-level objectives for the project',
      },
      {
        fieldName: 'Expected Benefits',
        fieldKey: 'expectedBenefits',
        placeHolder: 'Please detail the benefits you expected following the project',
      },
      {
        fieldName: 'What has been done',
        fieldKey: 'whatHasBeenDone',
        placeHolder: 'Please detail what has been done so far and will serve as starting point for the project',
      },
    ],
  },
  {
    sectionName: 'Scope & Deliverables',
    sectionKey: 'scopeAndDeliverables',
    fields: [
      {
        fieldName: 'Scope',
        fieldKey: 'scope',
        placeHolder: 'Please detail the scope of the project',
      },
      {
        fieldName: 'Expected Deliverables',
        fieldKey: 'expectedDeliverables',
        placeHolder: 'Please describe the main deliverables expected from the project',
      },
      {
        fieldName: 'Support required',
        fieldKey: 'supportRequired',
        placeHolder: 'Please describe what support you expect from the provider',
      },
    ],
  },
  {
    sectionName: 'Schedule and Budget',
    sectionKey: 'scheduleAndBudget',
    fields: [
      {
        fieldName: 'Project Expected Start Date',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
      {
        fieldName: 'Project Expected End Date',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
      {
        fieldName: 'Project Expected Duration',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
      {
        fieldName: 'Target Budget',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
    ],
  },
  {
    sectionName: 'Other Requirements',
    sectionKey: 'otherRequirements',
    fields: [
      {
        fieldName: 'Language',
        fieldKey: 'language',
        placeHolder: 'Please indicate your language requirements',
      },
      {
        fieldName: 'Team Set-up',
        fieldKey: 'teamSetup',
        placeHolder: 'Please indicate if you have specific requirements on the team set-up',
      },
      {
        fieldName: 'Location',
        fieldKey: 'location',
        placeHolder: 'Please indicate your requirements for where the work will be performed',
      },
      {
        fieldName: 'Other',
        fieldKey: 'other',
        placeHolder: 'Please indicate if you have additional requirements',
      },
    ],
  },
  {
    sectionName: 'Proposal Content',
    sectionKey: 'proposalContent',
    fields: [
      {
        fieldName: 'Expected Proposal Content',
        fieldKey: 'expectedProposalContent',
        placeHolder: 'Please detail the content you expect for the proposal',
      },
      {
        fieldName: 'References & Expertise',
        fieldKey: 'referenceAndExpertise',
        placeHolder: 'Please detail the information on references and expertise you expect to find with the proposal',
      },
      {
        fieldName: 'Fees & Workload',
        fieldKey: 'feesAndWorkload',
        placeHolder: 'Please specify how fees and workload should be detailed in the proposal',
      },
    ],
  },
  {
    sectionName: 'RFP Process',
    sectionKey: 'rfpProcess',
    fields: [
      {
        fieldName: 'Milestones',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
      {
        fieldName: 'Eligibility & Qualifications',
        fieldKey: 'eligibilityAndQualifications',
        placeHolder: 'Please detail your specific requirements to be eligible and qualified for this RFP',
      },
      {
        fieldName: 'Selection Criteria',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
      {
        fieldName: 'Q&A',
        fieldKey: 'questionsAndAnswers',
        placeHolder: 'Please describe how Q&A will be handled during the process',
      },
      {
        fieldName: 'Other Elements',
        fieldKey: 'otherQuestions',
        placeHolder: 'Please detail any other elements you would like to precise',
      },
      {
        fieldName: 'Key contact',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
    ],
  },
  {
    sectionName: 'Associated Documents',
    sectionKey: 'document',
    fields: [
      {
        fieldName: '',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the RFP creation',
      },
    ],
  },
];

export const SoWDefaultSections = [
  {
    sectionName: 'Preliminary Info',
    sectionKey: 'preliminaryInfo',
    fields: [
      {
        fieldName: 'Introduction & Parties',
        fieldKey: 'intro',
        placeHolder: 'Please provide details about the parties entering into this agreement.',
      },
      {
        fieldName: 'Recitals',
        fieldKey: 'recitals',
        placeHolder: 'Please specify the reasons for setting up this agreement.',
      },
      {
        fieldName: 'Definitions',
        fieldKey: 'terminology',
        placeHolder: 'Please provide definitions for the terminology and acronyms used in this agreement.',
      },
    ],
  },
  {
    sectionName: 'Project Description',
    sectionKey: 'projectDescription',
    fields: [
      {
        fieldName: 'Project Name',
        fieldKey: 'projectName',
        placeHolder: 'Please provide details on Project Name',
      },
      {
        fieldName: 'Project Context',
        fieldKey: 'projectContext',
        placeHolder: 'Please provide details on Project Context',
      },
      {
        fieldName: 'Project Objectives',
        fieldKey: 'projectObjective',
        placeHolder: 'Please provide details on Project Objectives',
      },
      {
        fieldName: 'Project Scope',
        fieldKey: 'projectScope',
        placeHolder: 'Please provide details on Project Scope',
      },
      {
        fieldName: 'Expected Services',
        fieldKey: 'servicesContext',
        placeHolder: 'Please provide details on Expected Services',
      },
      {
        fieldName: 'Expected Deliverables',
        fieldKey: 'expectedDeliverables',
        placeHolder: 'Please provide details on Expected Deliverables',
      },
      {
        fieldName: 'Term',
        fieldKey: 'subSection',
        subsection: [
          {
            fieldName: 'Expected Start Date',
            fieldKey: 'populatedInProject',
            placeHolder: 'This item is project specific and will be populated during the SOW creation.',
          },
          {
            fieldName: 'Expected End Date',
            fieldKey: 'populatedInProject',
            placeHolder: 'This item is project specific and will be populated during the SOW creation.',
          },
          {
            fieldName: 'Expected Duration',
            fieldKey: 'populatedInProject',
            placeHolder: 'This item is project specific and will be populated during the SOW creation.',
          },
        ]
      },
    ],
  },
  {
    sectionName: 'Project Management',
    sectionKey: 'projectManagement',
    fields: [
      {
        fieldName: 'Methodology & Timeline',
        fieldKey: 'populatedInProject',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Project Milestones & Deliverables',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Acceptance Criteria',
        fieldKey: 'acceptanceCriteria',
        placeHolder: 'Please provide details on Acceptance Criteria',
      },
      {
        fieldName: 'Project Organization',
        fieldKey: 'organization',
        placeHolder: 'Please provide details on Project Organization',
      },
      {
        fieldName: 'Staffing',
        fieldKey: 'staffing',
        placeHolder: 'Please provide details on Staffing',
      },
      {
        fieldName: 'Project Governance',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Language',
        fieldKey: 'language',
        placeHolder: 'Please provide details on Language',
      },
      {
        fieldName: 'Work Location',
        fieldKey: 'workLocation',
        placeHolder: 'Please provide details on Work Location',
      },
      {
        fieldName: 'Status Reports',
        fieldKey: 'statusReports',
        placeHolder: 'Please provide details on Status Reports',
      },
      {
        fieldName: 'Assumptions',
        fieldKey: 'assumptions',
        placeHolder: 'Please provide details on Assumptions',
      },
    ],
  },
  {
    sectionName: 'Terms & Conditions',
    sectionKey: 'termsAndConditions',
    fields: [
      {
        fieldName: 'Invoice Currency',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Maximum Project Budget',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Detailed Fees',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Invoicing Milestones',
        fieldKey: 'table',
        placeHolder: 'This item is project specific and will be populated during the SOW creation.',
      },
      {
        fieldName: 'Payment Terms',
        fieldKey: 'paymentTerms',
        placeHolder: 'Please provide details on Payment Terms ',
      },
      {
        fieldName: 'Invoicing Contact',
        fieldKey: 'invoicingContact',
        placeHolder: 'Please provide details about the contact for invoicing',
      },
      {
        fieldName: 'Contract Administration',
        fieldKey: 'contractAdministration',
        placeHolder: 'Please provide details about who will administer the contract',
      },
    ],
  },
  {
    sectionName: 'General Provisions',
    sectionKey: 'generalProvisions',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Other Provisions',
    sectionKey: 'otherProvisions',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Associated Documents',
    type: 'populatedInProject',
    placeHolder: 'This item is project specific and will be populated during the SOW creation.',
    fields: [],
  },
  {
    sectionName: 'Appendix',
    sectionKey: 'appendix',
    type: 'dynamic',
    fields: [],
  },
  {
    sectionName: 'Execution',
    type: 'populatedInProject',
    placeHolder: 'This item is project specific and will be populated during the SOW creation.',
    fields: [],
  },
  {
    sectionName: 'Change Orders',
    sectionKey: 'changeOrders',
    isStartNumber: true,
    fields: [
      {
        fieldName: 'Introduction',
        fieldKey: 'introduction',
        placeHolder: 'Please provide the details about the change orders introduction shown in the project management phase.',
      },
      {
        fieldName: 'Objectives & Deliverables',
        fieldKey: 'objective',
        placeHolder: 'Please provide the details about the objectives & deliverables shown in the project management phase.',
      },
      {
        fieldName: 'Project Milestones',
        fieldKey: 'milestones',
        placeHolder: 'Please provide the details about the project milestones shown in the project management phase.',
      },
      {
        fieldName: 'Invoicing and Payment',
        fieldKey: 'invoicing',
        placeHolder: 'Please provide the details about the invoicing and payment shown in the project management phase.',
      },
      {
        fieldName: 'Other Changes',
        fieldKey: 'otherChanges',
        placeHolder: 'Please provide the details about the other changes shown in the project management phase.',
      },
    ],
  },
];

export const CustomisationGeneralProvisions = [
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Rep & Warranties',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Confidentiality',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Intellectual Property',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Liability',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Indemnity',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Assignment',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'On-boarding',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Entitre Agreement',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Independent Contractor',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Notices',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Enforcement',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Additional Provisions',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Data Protection',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Force Majeure',
  },
  {
    isActive: true,
    commonData: {
      text: '',
    },
    title: 'Announcements',
  },
];

export const SowTabNumber = {
  preliminary: 1,
  projectDescription: 2,
  projectManagement: 3,
  termsAndTermination: 4,
  generalProvisions: 5,
  otherProvisions: 6,
  appendix: 8,
  signature: 9,
};

export const rfpNumber = {
  purpose: 1,
  background: 2,
  context: 3,
  scopeAndDeliverables: 4,
  scheduleAndBudget: 5,
  otherRequirements: 6,
  proposalContent: 7,
  rfpProcess: 8,
};

export const characterLimit = {
  city: 150,
  state: 150,
  email: 150,
  street: 200,
  tagline: 150,
  country: 150,
  website: 255,
  lastname: 20,
  firstname: 20,
  customURL: 250,
  middlename: 20,
  socialLink: 255,
  companyName: 250,
  companyOverview: 280,
  publicationTitle: 150,
  professionalSummary: 280,
};

export enum SowTabName {
  'PRELIMINARY' = 'PRELIMINARY',
  'PROJECT_DESCRIPTION' = 'PROJECT_DESCRIPTION',
  'PROJECT_MANAGEMENT' = 'PROJECT_MANAGEMENT',
  'TERMS_AND_CONDITIONS' = 'TERMS_AND_CONDITIONS',
  'GENERAL_PROVISIONS' = 'GENERAL_PROVISIONS',
  'OTHER_PROVISIONS' = 'OTHER_PROVISIONS',
  'APPENDIX' = 'APPENDIX',
  'SIGNATURE' = 'SIGNATURE'
}

export enum SowFieldName {
  // Preliminary
  'INTRODUCTION' = 'INTRODUCTION',
  'CLIENT_INFORMATION' = 'CLIENT_INFORMATION',
  'FIRM_INFORMATION' = 'FIRM_INFORMATION',
  'TERMINOLOGY' = 'TERMINOLOGY',
  'DEFINITION' = 'DEFINITION',
  'RECITALS' = 'RECITALS',
  // Project Description
  'PROJECT_NAME' = 'PROJECT_NAME',
  'PROJECT_CONTEXT' = 'PROJECT_CONTEXT',
  'PROJECT_OBJECTIVES' = 'PROJECT_OBJECTIVES',
  'PROJECT_SCOPE' = 'PROJECT_SCOPE',
  'EXPECTED_SERVICES_CONTEXT' = 'EXPECTED_SERVICES_CONTEXT',
  'EXPECTED_START_DATE' = 'EXPECTED_START_DATE',
  'EXPECTED_END_DATE' = 'EXPECTED_END_DATE',
  'EXPECTED_DURATION' = 'EXPECTED_DURATION',
  'EXPECTED_DELIVERABLES' = 'EXPECTED_DELIVERABLES',
  // Project Management
  'METHODOLOGY' = 'METHODOLOGY',
  'MILESTONES' = 'MILESTONES',
  'PROJECT_ORGANIZATION' = 'PROJECT_ORGANIZATION',
  'STAFFING' = 'STAFFING',
  'PROJECT_GOVERNANCE' = 'PROJECT_GOVERNANCE',
  'LANGUAGE' = 'LANGUAGE',
  'WORK_LOCATION' = 'WORK_LOCATION',
  'STATUS_REPORTS' = 'STATUS_REPORTS',
  'ACCEPTANCE_CRITERIA' = 'ACCEPTANCE_CRITERIA',
  'ASSUMPTIONS' = 'ASSUMPTIONS',
  // Terms & Conditions
  'DETAILED_FEES' = 'DETAILED_FEES',
  'PROJECT_COSTS' = 'PROJECT_COSTS',
  'INVOICING_MILESTONES' = 'INVOICING_MILESTONES',
  'PAYMENT_TERMS' = 'PAYMENT_TERMS',
  'PO_NUMBER' = 'PO_NUMBER',
  'INVOICING_CONTACT' = 'INVOICING_CONTACT',
  'MANAGEMENT_OF_CHANGES' = 'MANAGEMENT_OF_CHANGES',
  'TERMINATION' = 'TERMINATION',
  'CONTRACT_ADMINISTRATION' = 'CONTRACT_ADMINISTRATION',
  'FIXED_FEES' = 'FIXED_FEES',
  'DISCOUNT' = 'DISCOUNT',
  'VARIABLE_FEES' = 'VARIABLE_FEES',
  'OTHERS_FEES' = 'OTHERS_FEES',
  'TRAVEL_AND_EXPENSES' = 'TRAVEL_AND_EXPENSES',
  'FEES_PRECISION' = 'FEES_PRECISION',
  'T_&_E_PRECISION' = 'T_&_E_PRECISION',
  // General Provisions
  'REPRESENTATIONS_WARRANTIES_COVENANTS' = 'REPRESENTATIONS_WARRANTIES_COVENANTS',
  'CONFIDENTIALITY' = 'CONFIDENTIALITY',
  'INTELLECTUAL_PROPERTY' = 'INTELLECTUAL_PROPERTY',
  'LIABILITY' = 'LIABILITY',
  'INDEMNITY' = 'INDEMNITY',
  'ASSIGNMENT_SUBCONTRACTING' = 'ASSIGNMENT_SUBCONTRACTING',
  'ONBOARDING' = 'ONBOARDING',
  'ENTITRE_AGREEMENT' = 'ENTITRE_AGREEMENT',
  'INDEPENDENT_CONTRACTOR' = 'INDEPENDENT_CONTRACTOR',
  'NOTICES' = 'NOTICES',
  'ENFORCEMENT_SEVERABILITY' = 'ENFORCEMENT_SEVERABILITY',
  'ADDITIONAL_PROVISIONS' = 'ADDITIONAL_PROVISIONS',
  'DATA_PROTECTION' = 'DATA_PROTECTION',
  'FORCE_MAJEURE' = 'FORCE_MAJEURE',
  'ANNOUNCEMENT' = 'ANNOUNCEMENT',
  'INVOICE_CURRENCY' = 'INVOICE_CURRENCY',
  //Signature
  'EXECUTION_HEADER' = 'EXECUTION_HEADER'
}

export enum NdaTabName {
  'PRELIMINARY' = 'PRELIMINARY',
  'CONFIDENTIAL_INFORMATION' = 'CONFIDENTIAL_INFORMATION',
  'TERMS_TERMINATION' = 'TERMS_TERMINATION',
  'OBLIGATIONS' = 'OBLIGATIONS',
  'GENERAL_PROVISIONS' = 'GENERAL_PROVISIONS',
  'OTHER_PROVISIONS' = 'OTHER_PROVISIONS',
  'SIGNATURE' = 'SIGNATURE'
}

export enum NdaFieldName {
  // Preliminary
  'INTRODUCTION' = 'INTRODUCTION_PARTIES',
  'RECITALS' = 'RECITALS',
  'CLIENT_INFORMATION' = 'CLIENT_INFORMATION',
  'FIRM_INFORMATION' = 'FIRM_INFORMATION',
  'TERMINOLOGY' = 'TERMINOLOGY',
  'EXPECTED_START_DATE' = 'EXPECTED_START_DATE',
  'EXPECTED_END_DATE' = 'EXPECTED_END_DATE',
  'effectiveDate' = 'EFFECTIVE DATE',
  'termDate' = 'TERM DATE',
  'termText' = 'TERM TEXT',
  'termination' = 'TERMINATION',
  // Confidential information
  'DEFINITION' = 'DEFINITION',
  'EXCLUSIONS' = 'EXCLUSIONS',
  // Obligations
  'DISCLOSING_PARTY' = 'DISCLOSING_PARTY',
  'RECEIVING_PARTY' = 'RECEIVING_PARTY',
  'EXECUTION_HEADER' = 'EXECUTION_HEADER'
}

export enum SowFieldLabel {
  'INTRODUCTION' = 'INTRODUCTION & PARTIES',
  'CLIENT_INFORMATION' = 'CLIENT INFORMATION',
  'FIRM_INFORMATION' = 'FIRM INFORMATION',
  'TERMINOLOGY' = 'TERMINOLOGY',
  'DEFINITION' = 'DEFINITION',
  'RECITALS' = 'RECITALS',
  // Project Description
  'PROJECT_NAME' = 'PROJECT NAME',
  'PROJECT_CONTEXT' = 'PROJECT CONTEXT',
  'PROJECT_OBJECTIVES' = 'PROJECT OBJECTIVES',
  'PROJECT_SCOPE' = 'PROJECT SCOPE',
  'EXPECTED_SERVICES_CONTEXT' = 'EXPECTED SERVICES CONTEXT',
  'EXPECTED_START_DATE' = 'EXPECTED START DATE',
  'EXPECTED_END_DATE' = 'EXPECTED END DATE',
  'EXPECTED_DURATION' = 'EXPECTED DURATION',
  'EXPECTED_DELIVERABLES' = 'EXPECTED DELIVERABLES',
  // Project Management
  'METHODOLOGY' = 'METHODOLOGY & TIMELINE',
  'MILESTONES' = 'PROJECT MILESTONES & DELIVERABLES',
  'PROJECT_ORGANIZATION' = 'PROJECT ORGANIZATION',
  'STAFFING' = 'STAFFING',
  'PROJECT_GOVERNANCE' = 'PROJECT GOVERNANCE',
  'LANGUAGE' = 'LANGUAGE',
  'WORK_LOCATION' = 'WORK LOCATION',
  'STATUS_REPORTS' = 'STATUS REPORTS',
  'ACCEPTANCE_CRITERIA' = 'ACCEPTANCE CRITERIA',
  'ASSUMPTIONS' = 'Assumptions',
  // Terms & Conditions
  'PROJECT_COSTS' = 'PROJECT COSTS',
  'INVOICING_MILESTONES' = 'INVOICING MILESTONES',
  'PAYMENT_TERMS' = 'PAYMENT TERMS',
  'INVOICE_CURRENCY' = 'INVOICE CURRENCY',
  'PO_NUMBER' = 'PO NUMBER',
  'INVOICING_CONTACT' = 'INVOICING CONTACT',
  'MANAGEMENT_OF_CHANGES' = 'MANAGEMENT OF CHANGES',
  'TERMINATION' = 'TERMINATION',
  'CONTRACT_ADMINISTRATION' = 'CONTRACT ADMINISTRATION',
  'FIXED_FEES' = 'FIXED FEES',
  'DISCOUNT' = 'DISCOUNT',
  'VARIABLE_FEES' = 'VARIABLE FEES',
  'OTHERS_FEES' = 'OTHERS FEES',
  'TRAVEL_AND_EXPENSES' = 'TRAVEL AND EXPENSES',
  'DETAILED_FEES' = 'DETAILED FEES',
  'FEES_PRECISION' = 'PRECISION',
  'T_&_E_PRECISION' = 'PRECISION',
  // General Provisions
  'REPRESENTATIONS_WARRANTIES_COVENANTS' = 'REPRESENTATIONS, WARRANTIES AND COVENANTS',
  'CONFIDENTIALITY' = 'CONFIDENTIALITY',
  'INTELLECTUAL_PROPERTY' = 'INTELLECTUAL PROPERTY',
  'LIABILITY' = 'LIABILITY',
  'INDEMNITY' = 'INDEMNITY',
  'ASSIGNMENT_SUBCONTRACTING' = 'ASSIGNMENT, SUBCONTRACTING',
  'ONBOARDING' = 'ONBOARDING',
  'ENTITRE_AGREEMENT' = 'ENTITRE AGREEMENT',
  'INDEPENDENT_CONTRACTOR' = 'INDEPENDENT CONTRACTOR',
  'NOTICES' = 'NOTICES',
  'ENFORCEMENT_SEVERABILITY' = 'ENFORCEMENT SEVERABILITY',
  'ADDITIONAL_PROVISIONS' = 'ADDITIONAL PROVISIONS',
  'DATA_PROTECTION' = 'DATA PROTECTION',
  'FORCE_MAJEURE' = 'FORCE MAJEURE',
  'ANNOUNCEMENT' = 'ANNOUNCEMENT',
  //Signature
  'EXECUTION_HEADER' = 'EXECUTION_HEADER'
}

export enum SpecialDiffType {
  'DEFAULT',
  'EXTERNAL'
}

export const SoWValidationDialogText = {
  title: 'Confirm Validation',
  message: 'Are you sure you want to mark the changes in this field as validated?',
  successMessage: 'SOW field marked as validated',
};

export enum SoWFieldValidationStatus {
  INITIAL = 1,
  PENDING = 2,
  VALIDATED = 3,
}

export enum SoWDataFieldName {
  TEXT = 'text',
  DATE = 'date',
  DURATION = 'duration',
  AMOUNT = 'amount',
  DISCOUNT = 'discount',
  CURRENCY = 'currency',
}

export const StrategicPrioritiesYearMin = 1900;

export const ContractTypeList = [
  {
    value: 1,
    label: 'SOW',
  },
  {
    value: 2,
    label: 'NDA',
  },
  {
    value: 3,
    label: 'MSA',
  },
  {
    value: 4,
    label: 'CO',
  },
];

export const ContractTypesToBeListedInAddContracts = [
  {
    value: 1,
    label: 'SOW',
  },
  {
    value: 2,
    label: 'NDA',
  },
  {
    value: 3,
    label: 'MSA',
  },
];

export enum UploadPath {
  CONTRACTS = 'Contracts',
  SUPER_ADMIN_CONTRACTS = 'admin/contracts',
  STRATEGIC_PRIORITIES = 'StrategicPriorities',
  FIRM_SPECIFIC_INFO_DOCUMENTS = 'firm/specificInfoDocuments',
  MEETINGS = 'meetings',
  LIBRARY = 'library',
}

export const ConsultingFirmProfileTabs = [
  {
    routeName: 'Company Profile',
    routeUrl: 'company-profile',
  },
  {
    routeName: 'Legal Entities',
    routeUrl: 'legal-entities',
  },
  {
    routeName: 'Partners',
    routeUrl: 'contacts',
  },
  {
    routeName: 'Projects',
    routeUrl: 'projects',
  },
  {
    routeName: 'Analytics',
    routeUrl: 'analytics',
  },
  {
    routeName: 'Contracts',
    routeUrl: 'administrative',
  },
  {
    routeName: 'Meetings',
    routeUrl: 'meetings',
  },
  {
    routeName: 'Tasks',
    routeUrl: 'tasks',
  },
  {
    routeName: 'Settings',
    routeUrl: 'settings',
  },
];

export const ConsultingFirmProfileTabsFirmSide = [
  {
    routeName: 'General Information',
    routeUrl: 'general-information',
  },
];

export const ConsultingFirmTabsSuperAdminSide = [
  {
    routeName: 'General Information',
    routeUrl: 'general-information',
  },
  {
    routeName: 'Specific Information',
    routeUrl: 'specific-information',
  },
];

export enum ContractsDocumentTypes {
  UploadedDocument = 1,
  DriveLink = 2,
  ProgressiveRFP = 3,
  ProgressiveProposal = 4,
  ProgressiveSow = 5,
}

export enum ConsultingFirmTabs {
  GeneralInformation = 'general-information',
  SpecificInformation = 'specific-information',
  legalEntities = 'legal-entities',
  Contacts = 'contacts',
  Projects = 'projects',
  Analytics = 'analytics',
  Contracts = 'administrative',
  Meetings = 'meetings',
  Actions = 'tasks',
  Locaton = 'location',
}

export const MeetingNatureList = [
  {
    value: 1,
    label: 'Introduction',
  },
  {
    value: 2,
    label: 'Performance Review',
  },
  {
    value: 3,
    label: 'Other',
  },
];

export const SocialUrlRegex = {
  linkedIn: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)\/([-a-zA-Z0-9\S]+)\/*/,
  facebook: /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/,
  instagram: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/,
  website: /^(https?:\/\/)?([\da-zа-я\.\-_]+)\.([a-zа-я\._]{2,6})([a-zа-я\d\.\-\?\/&=#%_]*)*/,
  twitter: /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/,
  youtube: /(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+/,
};
export const WebsiteRegex = /^(https?:\/\/)?([\da-zа-я\.\-_]+)\.([a-zа-я\._]{2,6})([a-zа-я\d\.\-\?\/&=#%_]*)*/;
export const FocusChartColors = [
  '#FFA600',
  '#F95D6A',
  '#003F5C',
  '#26D7AE',
  '#FF7C43',
  '#007883',
  '#665191',
  '#97D9FF',
  '#DA006D',
  '#8857B9',
  '#00789D',
  '#A05195',
  '#BB389C',
  '#E00034',
  '#D45087',
  '#5FB7D4',
  '#0074B5',
  '#1BAA2F',
  '#2F4B7C',
  '#456AC1',
];

export const CompanyAnalyticsByRegion = {
  colorForPercentageVal: [
    {
      from: 0.1,
      to: 20,
      color: '#FFB473',
      label: '< 20%',
    },
    {
      from: 20,
      to: 40,
      color: '#FF9940',
      label: '20% - 40%',
    },
    {
      from: 40,
      to: 60,
      color: '#FF8820',
      label: '40% - 60%',
    },
    {
      from: 60,
      to: 80,
      color: '#FF7700',
      label: '60% - 80%',
    },
    {
      from: 80,
      to: 100,
      color: '#EC6E00',
      label: '> 80%',
    },
    {
      from: 0,
      to: 0,
      color: '#E5E5E5',
      label: 'Not assigned',
    },
  ],
  colorForNumberVal: [
    {
      from: 0.01,
      to: 10,
      color: 'rgba(255, 119, 0, 0.2)',
      label: '< 10M',
    },
    {
      from: 10,
      to: 20,
      color: 'rgba(255, 119, 0, 0.4)',
      label: '10M - 20M',
    },
    {
      from: 20,
      to: 30,
      color: 'rgba(255, 119, 0, 0.6)',
      label: '20M - 30M',
    },
    {
      from: 30,
      to: 40,
      color: 'rgba(255, 119, 0, 0.8)',
      label: '30M - 40M',
    },
    {
      from: 40,
      to: 50,
      color: 'rgba(255, 119, 0, 1.0)',
      label: '40M - 50M',
    },
    {
      from: 0,
      to: 0,
      color: '#E5E5E5',
      label: 'Not assigned',
    },
  ],
};

export const CompanyAnalyticsByCapabilites = {
  colorForPercentageVal: [
    {
      from: 0,
      to: 10,
      color: 'rgba(255, 119, 0, 0.1)',
    },
    {
      from: 10,
      to: 20,
      color: 'rgba(255, 119, 0, 0.2)',
    },
    {
      from: 20,
      to: 30,
      color: 'rgba(255, 119, 0, 0.3)',
    },
    {
      from: 30,
      to: 40,
      color: 'rgba(255, 119, 0, 0.4)',
    },
    {
      from: 40,
      to: 50,
      color: 'rgba(255, 119, 0, 0.5)',
    },
    {
      from: 50,
      to: 60,
      color: 'rgba(255, 119, 0, 0.6)',
    },
    {
      from: 60,
      to: 70,
      color: 'rgba(255, 119, 0, 0.7)',
    },
    {
      from: 70,
      to: 80,
      color: 'rgba(255, 119, 0, 0.8)',
    },
    {
      from: 80,
      to: 90,
      color: 'rgba(255, 119, 0, 0.9)',
    },
    {
      from: 90,
      to: 100,
      color: 'rgba(255, 119, 0, 1.0)',
    },
  ],
  colorForNumberVal: [
    {
      from: 0,
      to: 5,
      color: 'rgba(255, 119, 0, 0.1)',
    },
    {
      from: 5,
      to: 10,
      color: 'rgba(255, 119, 0, 0.2)',
    },
    {
      from: 10,
      to: 15,
      color: 'rgba(255, 119, 0, 0.3)',
    },
    {
      from: 15,
      to: 20,
      color: 'rgba(255, 119, 0, 0.4)',
    },
    {
      from: 20,
      to: 25,
      color: 'rgba(255, 119, 0, 0.5)',
    },
    {
      from: 25,
      to: 30,
      color: 'rgba(255, 119, 0, 0.6)',
    },
    {
      from: 30,
      to: 35,
      color: 'rgba(255, 119, 0, 0.7)',
    },
    {
      from: 35,
      to: 40,
      color: 'rgba(255, 119, 0, 0.8)',
    },
    {
      from: 40,
      to: 45,
      color: 'rgba(255, 119, 0, 0.9)',
    },
    {
      from: 45,
      to: 50,
      color: 'rgba(255, 119, 0, 1.0)',
    },
  ],
};

export const CharacterLimit = {
  name: 20,
  companyName: 150,
  email: 150,
  tagline: 150,
  overview: 280,
  address1: 200,
  address2: 150,
  customURL: 250,
  websiteURL: 255,
  socialLinkURL: 255,
  title: 150,
};

export enum CompanyType {
  PrivatelyHeld = 'Privately Held',
  PubilcCompany = 'Public Company',
  NotSpecified = 'Not Specified',
  Partnership = 'Partnership',
  SoleProprietorship = 'Sole Proprietorship',
  Nonprofit = 'Nonprofit',
}

export const EmployeesRange = ['2-10', '11-50', '51-200', '201-500', '501-1,000', '1,001-5,000', '5,001-10,000', '10,001+'];

export const SuggestIndustries = ['Managment Consulting', 'Academia', 'Other'];

export const DiversityList = [
  { label: 'Woman Owned' },
  { label: 'Veteran Owned' },
  {
    label: 'Minority Owned',
  },
  {
    label: 'LGBTQ Owned',
  },
  {
    label: 'Disabled Owned',
  },
];

export const imageQuality = 35;
export const imageRatio = 100;
export const imageUploadFileTypes = '.png, .jpg, .jpeg';
export const imageUploadMaxFileSizeMB = 10;

export enum UploadType {
  'Profile',
  'Cover',
  'ImageAssets',
  'Other',
  'Sow',
  'Nda',
  'Msa',
  'AdminContract',
  'LegalEntity',
  'Chat',
  'Meetings',
  'MsaAppendix',
  'Project',
  'ProjectManagement',
  'changeOrder',
  SA_CLIENT_CONTRACT = 'SA_CLIENT_CONTRACT',
  SA_PROVIDER_CONTRACT = 'SA_PROVIDER_CONTRACT',
  SA_LE_JOINER_CONTRACTS = 'SA_LE_JOINER_CONTRACTS',
  SA_PROVIDER_KYS = 'SA_PROVIDER_KYS',
  CLIENT_CONTRACTS = 'CLIENT_CONTRACTS',
  SA_CHAT = 'SA_CHAT',
  SA_SOW_TEMPLATE = 'SA_SOW_TEMPLATE',
  SA_MSA_TEMPLATE = 'SA_MSA_TEMPLATE',
  SA_KYS_QUESTIONNAIRE = 'SA_KYS_QUESTIONNAIRE',
  CLIENT_USER_CHAT = 'CLIENT_USER_CHAT',
  PROVIDER_USER_CHAT = 'PROVIDER_USER_CHAT',
  PROVIDER_USER_AVATARS = 'PROVIDER_USER_AVATARS',
  CLIENT_PM_FILES = 'CLIENT_PM_FILES',
  CLIENT_PM_CHAT = 'CLIENT_PM_CHAT',
  CLIENT_PM_MEETINGS = 'CLIENT_PM_MEETINGS',
  LEGAL_ENTITIES_QUESTIONNAIRES = 'LEGAL_ENTITIES_QUESTIONNAIRES',
  STRATEGIC_PRIORITIES = 'STRATEGIC_PRIORITIES',
  DEMAND = 'DEMAND',
  DEMAND_OTHER_ELEMENT = 'DEMAND_OTHER_ELEMENT',
  PROVIDER_MEETINGS = 'PROVIDER_MEETINGS',
  LIBRARY = 'LIBRARY',
  CLIENT_SOW_ASSC_DOCUMENT = 'CLIENT_SOW_ASSC_DOCUMENT',
  CLIENT_RFP_ASSC_DOCUMENT = 'CLIENT_RFP_ASSC_DOCUMENT',
  CLIENT_PROPOSALS = 'CLIENT_PROPOSALS',
  CLIENT_PM_INVOICES = 'CLIENT_PM_INVOICES',
  CLIENT_PM_MILESTONES = 'CLIENT_PM_MILESTONES',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  SOW_TEMPLATE = 'SOW_TEMPLATE',
  MSA_TEMPLATE = 'MSA_TEMPLATE',
  DEMAND_TEMPLATE = 'DEMAND_TEMPLATE',
  QUESTIONNAIRE_TEMPLATE = 'QUESTIONNAIRE_TEMPLATE',
  CLIENT_USER_AVATAR = 'CLIENT_USER_AVATAR',
  CLIENT_SOW_CO_CONTRACTS = 'CLIENT_SOW_CO_CONTRACTS',
  CLIENT_MSA_APPENDIX = 'CLIENT_MSA_APPENDIX',
  CLIENT_SOW_APPENDIX = 'CLIENT_SOW_APPENDIX',
  SA_QUILL = 'SA_QUILL',
  CLIENT_QUILL = 'CLIENT_QUILL',
  PROVIDER_QUILL = 'PROVIDER_QUILL',
  'CLIENT_SCORING_DOCUMENTS' = 'CLIENT_SCORING_DOCUMENTS'
}

export enum UploadTypes {
  Crop = 'crop',
  Upload = 'upload',
}

export const profilePhotoMaxResolution = {
  width: 118,
  height: 118,
};

export const coverPhotoMaxResolution = {
  width: 388,
  height: 130,
};

export const employeeRanges = ['2-10', '11-50', '51-200', '201-500', '501-1,000', '1,001-5,000', '5,001-10,000', '10,001+'];

export enum ServicedBusiness {
  StartUp = 'Start-ups and Very Small Business',
  Small = 'Small Companies',
  Medium = 'Medium-Sized Companies',
  Large = 'Large Companies',
  Fortune = 'Fortune 1000 Companies',
  NotSpecified = 'Not Specified',
}

export enum Category {
  LAYOUT = 'Layout',
  TEMPLATE = 'Template',
}

export enum OtherElementsFieldType {
  TEMPLATE = 'template',
  UPLOAD = 'upload'
}

export enum CurrencyType {
  CONVERSION = 'Conversion',
  MANAGEMENT = 'Management',
  ARCHIVED = 'Archived',
}

export enum TemplateEditorMode {
  ADD = 'add',
  EDIT = 'edit',
}

export enum TemplateType {
  SOW = 'sow',
  RFP = 'rfp',
  NDA = 'nda',
  MSA = 'msa',
  MARKET_PLACE = 'market_place'
}

export enum ClientRfpSettingsTabs {
  MILESTONES = 'milestones',
  SELECTION_CRITERIA = 'selection-criteria',
  EXPORT_HISTORY = 'export-history',
  SETTINGS = 'settings',
}

export enum ClientSowSettingsTabs {
  EXPORT_HISTORY = 'export-history',
}

export enum ClientSourcingSettingsTabs {
  SETTINGS = 'settings',
  EXPORT_HISTORY = 'export-history',
}

export enum ProjectAnalyticsChartType {
  ProjectByMonth = 'ProjectByMonth',
  ProjectByCapability = 'ProjectByCapability',
  ProjectByOrganisation = 'ProjectByOrganisation',
  ProjectByBusinessUnit = 'ProjectByBusinessUnit',
  ProjectByRegion = 'ProjectByRegion',
}

export const ndaValidationDialogText = {
  title: 'Confirm Validation',
  message: 'Are you sure you want to mark the changes in this field as validated?',
  successMessage: 'NDA field marked as validated',
};

export const ProjectAnalyticsChartColor = [
  {
    from: 1,
    to: 10,
    color: 'rgba(255, 119, 0, 0.2)',
    label: '< 10',
  },
  {
    from: 10,
    to: 20,
    color: 'rgba(255, 119, 0, 0.4)',
    label: '10 - 20',
  },
  {
    from: 20,
    to: 30,
    color: 'rgba(255, 119, 0, 0.6)',
    label: '20 - 30',
  },
  {
    from: 30,
    to: 40,
    color: 'rgba(255, 119, 0, 0.8)',
    label: '30 - 40',
  },
  {
    from: 40,
    to: 50,
    color: 'rgba(255, 119, 0, 1.0)',
    label: '40 - 50',
  },
  {
    from: 0,
    to: 0,
    color: '#E5E5E5',
    label: 'Not assigned',
  },
];

export const SpecificInformationQualificationOptions = [
  {
    key: 'complianceData',
    value: 'Compliance Data',
    documents: [],
  },
  {
    key: 'certificationData',
    value: 'Certification Data',
    documents: [],
  },
  {
    key: 'legalFiscalData',
    value: 'Legal & Fiscal Data',
    documents: [],
  },
  {
    key: 'onboardingData',
    value: 'Other Registration / On Boarding Data',
    documents: [],
  },
];

export enum ActionType {
  RESET = 'reset',
  DELETE = 'delete',
}

export enum ProjectFastTracking {
  RFP = 1,
  SoW = 2,
  PROJECT_MANAGEMENT = 3,
  PERFORMANCE = 4,
  PM = '3',
  PERFORMANCE_MANAGEMENT = '4',
}

export const MarketExplorationCompanySizeFilterValues = ['2-10', '11-50', '51-200', '201-500', '501-1,000', '1,001-5,000', '5,001-10,000', '10,001+'];

export const clientManagementModuleObject = [
  {
    name: 'Clients',
    path: '/dashboard/clients',
  },
  {
    name: 'Clients Invoices',
    path: '/dashboard/clients/billings',
  },
  {
    name: 'Admin Change Requests',
    path: '/dashboard/clients/admin-change-requests',
  },
  {
    name: 'MSP Clients',
    path: '/dashboard/msp-clients',
  },
];

export const adminProviderManagementModuleObject = [
  {
    name: 'Providers',
    path: '/dashboard/consulting-firms',
  },
  {
    name: 'Suggested Providers',
    path: '/dashboard/suggest-firms',
  },
  {
    name: 'Providers Invoices',
    path: '/dashboard/consulting-firms/billings',
  },
  {
    name: 'Providers Admin Change Request',
    path: '/dashboard/providers/admin-change-requests',
  },
];

export const AdminMarketPlaceModuleObject = [
  {
    name: 'Projects',
    path: '/dashboard/marketplace/projects',
  },
  {
    name: 'Analytics',
    path: '/dashboard/marketplace/analytics',
  }
];

export const adminTaxonomyManagementModuleObject = [
  {
    name: 'Industries',
    path: '/dashboard/industries',
  },
  {
    name: 'Capabilities',
    path: '/dashboard/capabilities',
  },
  {
    name: 'Regions',
    path: '/dashboard/regions',
  },
  {
    name: 'Countries',
    path: '/dashboard/countries',
  },
];

export const adminLocalizationManagementModuleObject = [
  {
    name: 'Email Templates',
    path: '/dashboard/emails',
  },
  {
    name: 'Notification Templates',
    path: '/dashboard/notification-contents',
  },
  {
    name: 'Survey Questions',
    path: '/dashboard/questions',
  },
];

export const adminScsManagementModuleObject = [
  {
    name: 'Content',
    path: '/dashboard/scsAcademy',
  },
  {
    name: 'Categories',
    path: '/dashboard/blogCatagory',
  },
];

export enum AdminClientListDrawerType {
  FILTER,
  MANAGE_MODULES,
  INVITE_USER,
}

export enum AdminProviderListDrawerType {
  FILTER,
  ADD_PROVIDER
}

export const AdminProjectListDrawerType = 'FILTER';

export const TeamScoringColumnNames = ['Average', 'Median'];

export enum NdaOtherTabsType {
  MISCELLANEOUS = 'miscellaneous',
  OTHERCLAUSES = 'otherClauses',
}

export enum CompanyAnalyticsCharts {
  spendTrend = 0,
  consultingSpend = 1,
  consultingScore = 2,
}

export enum ChartsScreenType {
  Dashboard = 'dashboard',
  CompanyAnalytics = 'company_analytics',
}
export const NdaTabDetails = {
  preliminaryInfo: {
    tabName: 'Preliminary Info',
    fields: {
      introduction: 'Introduction & Parties',
      clientInformation: 'Client Information',
      firmInformation: 'Firm Information',
      terminology: 'Terminology & Acronyms',
      expectedStartDate: 'Expected Start Date',
      expectedEndDate: 'Expected End Date',
      recitals: 'Recitals',
    },
  },
  TermsAndTermination: {
    tabName: 'Terms & Termination',
  },
  confidentialInfo: {
    tabName: 'Confidential Information',
    fields: {
      definitions: 'Definition of Confidential Information',
      exclusions: 'Exclusions from Confidential Information',
    },
  },
  obligations: {
    tabName: 'Obligations',
    fields: {
      disclosingPartyObligations: 'Obligations of Disclosing Party',
      receivingPartyObligations: 'Obligations of Receiving Party',
    },
  },
  generalProvisions: { tabName: 'General Provisions' },
  otherProvisions: { tabName: 'Other Provisions' },
};

export const defaultProfilePhoto = 'assets/images/user-image.png';
export const DefaultGroupPhoto = 'assets/images/user.png';

export const ProjectSteps = ['Demand', 'RFP', 'Sourcing & Invitation', 'Briefing', 'Selection', 'SOW', 'Project Management', 'Performance'];

export const ProjectStepsFirm = ['RFP', 'Briefing', 'Selection', 'SOW', 'Project Management', 'Performance'];
export const defaultTextDiffValues = { firmData: { text: '' }, commonData: { text: '' }, clientData: { text: '' } };
export const defaultDateDiffValues = { firmData: { date: '' }, commonData: { date: '' }, clientData: { date: '' } };

export const TermTerminationTab = [
  {
    title: '2_1_Effective_date',
    type: 'default',
    fieldType: 'date',
    commentHeader: 'Effective Date',
    placeholder: 'Effective Date',
    fieldName: 'effectiveDate',
    key: 'EFFECTIVE_DATE',
    value: defaultDateDiffValues,
    max: 'RENEWAL_DATE',
  },
  {
    title: '2_2_Term',
    type: 'dateAndText',
    fieldType: 'dateAndText',
    placeholder: 'Please describe the principles applying for the term.',
    commentHeader: 'Term',
    textFieldName: 'termText',
    dateFieldName: 'termDate',
    datePlaceholder: 'Term Date',
    key: 'TERM',
    date: defaultDateDiffValues,
    text: defaultTextDiffValues,
    min: 'EFFECTIVE_DATE',
  },
  {
    title: '2_3_Termination',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Termination',
    placeholder: 'Please detail the principles for terminating this agreement.',
    key: 'TERMINATION',
    fieldName: 'termination',
    value: defaultTextDiffValues,
  },
  {
    title: '2_4_Renewal_Date',
    type: 'dateAndText',
    fieldType: 'dateAndText',
    commentHeader: 'Renewal Date',
    placeholder: 'Please describe the principles applying for the renewal.',
    textFieldName: 'renewalText',
    dateFieldName: 'renewalDate',
    datePlaceholder: 'Renewal Date',
    key: 'RENEWAL_DATE',
    date: defaultDateDiffValues,
    text: defaultTextDiffValues,
    min: 'EFFECTIVE_DATE',
  },
];

export enum TermsAndTermination {
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  TERM = 'TERM',
  TERMINATION = 'TERMINATION',
  RENEWAL_DATE = 'RENEWAL_DATE',
}

export enum BlogEditorMode {
  ADD = 'Add',
  EDIT = 'Edit',
  DELETE = 'Delete',
}
export enum SignatureStep2Title {
  FinalVersion = 'Final Executed Version',
  ClientVersion = 'Version signed by client',
  FirmVersion = 'Version signed by provider',
  Initial = 'Signature',
}

export const SignatureDefaultText =
  '<p>IN WITNESS WHEREOF, the parties, intending to be legally bound, have caused this Agreement to be executed by their duly authorized representatives, as of the Effective Date.</p>';

export enum DrawerNames {
  MilestoneCriteria = 'MilestoneCriteria',
  RiskDrawer = 'RiskDrawer',
  OnBoardingDrawer = 'onboardingDrawer',
}

export const BlogCatagories = ['Consulting Market', 'Best Practices', 'Webinars', 'Miscellaneous'];

export const DiversityOptions = ['Woman Owned', 'Veteran Owned', 'Minority Owned', 'LGBTQ Owned', 'Disabled Owned'];

export enum TargetProfileTypes {
  Diversity = 'Diversity',
  CompanySize = 'CompanySize',
}

export enum DemographyKey {
  Capability = 'capability',
  Industry = 'industry',
  ChildCapability = 'childCapability',
  ChildIndustry = 'childIndustry',
}

export const CommonCommentsIdKeyValue = {
  msa: 'msaId',
  nda: 'ndaId',
  sow: 'sowId',
  'change-order': 'changeId'
};

export enum ProjectListType {
  OPPORTUNITIES = 'opportunities',
  PROJECTS = 'projects',
}

export const MsaTabDetails = {
  preliminaryInfo: {
    tabName: 'Preliminary Info',
    fields: {
      introduction: 'Introduction',
      terminology: 'Terminology & Acronyms',
    },
  },
  billingAndPayment: {
    tabName: 'Billing & Payment',
  },
  TermsAndTermination: {
    tabName: 'Terms & Termination',
  },
  generalProvisions: { tabName: 'General Provisions' },
  otherProvisions: { tabName: 'Other Provisions' },
};

export enum MsaTabName {
  'PRELIMINARY_INFO' = 'PRELIMINARY_INFO',
  'BILLING_PAYMENT' = 'BILLING_PAYMENT',
  'TERMS_TERMINATION' = 'TERMS_TERMINATION',
  'GENERAL_PROVISIONS' = 'GENERAL_PROVISIONS',
  'OTHER_PROVISIONS' = 'OTHER_PROVISIONS',
  'SIGNATURE' = 'SIGNATURE'
}

export enum MsaFieldName {
  'INTRODUCTION' = 'INTRODUCTION',
  'terminology' = 'TERMINOLOGY',
  'effectiveDate' = 'EFFECTIVE DATE',
  'termDate' = 'TERM DATE',
  'termText' = 'TERM TEXT',
  'termination' = 'TERMINATION',
  'GENERAL_PROVISIONS' = 'GENERAL_PROVISIONS',
  'OTHER_PROVISIONS' = 'OTHER_PROVISIONS',
  'recitals' = 'RECITALS',
  'feeStructure' = 'FEE_STRUCTURE',
  'paymentTerm' = 'PAYMENT_TERM',
  'invoicingProcedure' = 'INVOICING_PROCEDURE',
  'policies' = 'POLICIES',
  'taxes' = 'TAXES',
  'rebatesDiscounts' = 'REBATE_DISCOUNTS',
  'EXECUTION_HEADER' = 'EXECUTION_HEADER'
}

export const MsaValidationDialogText = {
  title: 'Confirm Validation',
  message: 'Are you sure you want to mark the changes in this field as validated?',
  successMessage: 'MSA field marked as validated',
};

export const PreliminaryTabValues = [
  {
    title: '1_1_Introduction & Parties',
    type: 'text',
    fieldType: 'text',
    commentHeader: 'Introduction & Parties',
    placeholder: 'Please provide details about the parties entering into this agreement',
    fieldName: 'introduction',
    key: 'INTRODUCTION',
    noDiffText: '',
  },
  {
    title: '1_2_Recitals',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Recitals',
    placeholder: 'Please specify the reasons for setting up this agreement',
    fieldName: 'recitals',
    key: 'RECITALS',
    value: defaultTextDiffValues,
  },
  {
    title: '1_3_Definitions',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Definitions',
    placeholder: 'Please provide definitions for the terminology and acronyms used in this agreement',
    key: 'TERMINOLOGY',
    fieldName: 'terminology',
    value: defaultTextDiffValues,
  },
];

export const PaymentTabValues = [
  {
    title: '1_1_Fee Structure',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Fee Structure',
    placeholder: 'Please provide details on Fee Structure',
    fieldName: 'feeStructure',
    key: 'FEESTRUCTURE',
    value: defaultTextDiffValues,
  },
  {
    title: '1_2_Payment Terms',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Payment Terms',
    placeholder: 'Please provide details on Payment Terms',
    key: 'PAYMENTTERM',
    fieldName: 'paymentTerm',
    value: defaultTextDiffValues,
  },
  {
    title: '1_3_Invoicing procedures',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Invoicing procedures',
    placeholder: 'Please provide details on Invoicing procedures',
    key: 'INVOICINGPROCEDURE',
    fieldName: 'invoicingProcedure',
    value: defaultTextDiffValues,
  },
  {
    title: '1_4_Expenses and Reimbursement Policies',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Expenses and Reimbursement Policies',
    placeholder: 'Please provide details on Expenses and Reimbursement Policies',
    key: 'POLICIES',
    fieldName: 'policies',
    value: defaultTextDiffValues,
  },
  {
    title: '1_5_Taxes',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Taxes',
    placeholder: 'Please provide details on Taxes',
    key: 'TAXES',
    fieldName: 'taxes',
    value: defaultTextDiffValues,
  },
  {
    title: '1_6_Rebates and Discounts',
    type: 'default',
    fieldType: 'text',
    commentHeader: 'Rebates and Discounts',
    placeholder: 'Please provide details on Rebates and Discounts',
    key: 'REBATESDISCOUNTS',
    fieldName: 'rebatesDiscounts',
    value: defaultTextDiffValues,
  },
];

export enum PreliminaryFields {
  RECITALS = 'RECITALS',
  INTRODUCTION = 'INTRODUCTION',
  TERMINOLOGY = 'TERMINOLOGY',
}

export enum PaymentFields {
  FEESTRUCTURE = 'FEESTRUCTURE',
  PAYMENTTERM = 'PAYMENTTERM',
  INVOICINGPROCEDURE = 'INVOICINGPROCEDURE',
  POLICIES = 'POLICIES',
  TAXES = 'TAXES',
  REBATESDISCOUNTS = 'REBATESDISCOUNTS',
}

export const definitionTab = ['ndaPreliminaryTerminologyTerm', 'ndaPreliminaryTerminologyDefinition', 'ndaPreliminaryTerminologyDate', 'ndaPreliminaryAction'];

export const MsaDynamicTabNumbers = {
  preliminary: 1,
  term: 2,
  Services: 3,
  'Standards of Performance': 4,
  'Billing & Payment': 5,
  'General Provisions': 6,
  'Other Provisions': 7,
  Appendix: 8,
};

export const MsaDynamicTabRoute = {
  Services: 'services',
  'Standards of Performance': 'standards-performance',
  'General Provisions': 'general-provisions',
  'Other Provisions': 'other-provisions',
  Appendix: 'appendix-sections',
};

export const MsaDynamicFieldName = {
  Services: 'services',
  'Standards of Performance': 'standardsOfPerformance',
  'General Provisions': 'generalProvisions',
  'Other Provisions': 'otherProvisions',
  Appendix: 'appendix',
};
export const MsaDynamicTabNames = ['Services', 'Standards of Performance', 'Billing & Payment', 'General Provisions', 'Other Provisions', 'Appendix'];

export const MsaDynamicTabKey = {
  Services: 'SERVICES',
  'Standards of Performance': 'STANDARDS_OF_PERFORMANCE',
  'General Provisions': 'GENERAL_PROVISIONS',
  'Other Provisions': 'OTHER_PROVISIONS',
  Appendix: 'APPENDIX',
};

export const MsaDynamicFieldFilePath = {
  Services: 'contract/msa/services',
  'Standards of Performance': 'contract/msa/sop',
  'General Provisions': 'contract/msa/general-provisions',
  'Other Provisions': 'contract/msa/other-provisions',
  Appendix: 'contract/msa/appendix  ',
};

export const allDynamicFieldName = ['services', 'standardsOfPerformance', 'generalProvisions', 'otherProvisions', 'appendix'];

export const ndaDynamicTabNames = ['General Provisions', 'Other Provisions'];
export const sowDynamicTabNames = ['General Provisions', 'Other Provisions', 'Appendix'];

export enum DynamicFieldDataTypes {
  QUILL = 'quill',
  UPLOAD = 'upload',
  TEMPLATE = 'template',
}

export const SoWDynamicFieldValues = {
  'General Provisions': [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Liabilities, Warranties, Indemnification and Insurance',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Intellectual Property',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Data Privacy & Security',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Confidentiality',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Return or destruction of confidential information',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Publicity & Communication',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Audit and Inspection Rights',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Independent Contractor',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Conflicts of Interest',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Non-Compete',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'On boarding process',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Non-Solicitation',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Business Continuity',
    },
  ],
  'Other Provisions': [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Entire Agreement',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Severability',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Headings',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Order of Precedence',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Assignment and delegation',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'No Third Party Beneficiary',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Notices',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Force Majeure',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Governing Law',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Right to seek an injunction',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Dispute Resolution',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Legal Fees',
    },
  ],
  Appendix: []
};

export const NdaDynamicFieldValues = {
  'General Provisions': [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Legal Obligation to Disclose',
      id: '5a392bd4-cebb-47df-83f8-c89580923c4b',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Return of Confidential Information',
      id: 'd693b5b9-26b0-4ee0-a58e-627034fdac3a',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Governing Law',
      id: 'd07aea40-5461-4e38-a908-e62ce4e1e07e',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Right to seek an injunction',
      id: 'e99960a3-53a1-4b0e-bc92-69055e539684',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Remedies',
      id: 'b6bfcce2-afa4-4e15-9566-7af607f77278',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Legal Fees',
      id: '9c4096eb-432d-4d93-92d4-d2d8dfc8f27e',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Dispute Resolution',
      id: '35a0465b-6f54-46a5-b024-590f4907bfdf',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'No Obligation',
      id: '2749eaea-bc3b-48a6-a55b-c138988aa7cf',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Inspection Rights',
      id: '4f832511-11f2-4548-bebc-466f101596ce',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Publicity & Communication',
      id: '2e6a095a-1dcb-40cb-b778-07ce5c6c516c',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: 'quill',
      title: 'Non-Solicitation',
      id: '359ae51f-e029-44e9-a035-5ef11ca5c99e',
    },
  ],
  'Other Provisions': [],
};

export const MsaDefaultDynamicFieldsValues = {
  Services: [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Services Provided',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Statement of Work',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Management of Changes',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Geographic Location',
    },
  ],
  'Standards of Performance': [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'General',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Deliverables',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Company Personnel',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Key Personnel',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Reporting',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Compliance',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Supplier Code of Conduct',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Diversity',
    },
  ],
  'General Provisions': [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Liabilities, Warranties, Indemnification and Insurance',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Intellectual Property',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Data Privacy & Security',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Confidentiality',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Return or destruction of confidential information',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Publicity & Communication',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Audit and Inspection Rights',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Independent Contractor',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Conflicts of Interest',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Non-Compete',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'On boarding process',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Non-Solicitation',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Business Continuity',
    },
  ],
  'Other Provisions': [
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Entire Agreement',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Severability',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Headings',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Order of Precedence',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Assignment and delegation',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'No Third-Party Beneficiary',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Notices',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Force Majeure',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Governing Law',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Right to seek an injunction',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Dispute Resolution',
    },
    {
      isActive: true,
      commonData: {
        text: '',
      },
      type: DynamicFieldDataTypes.QUILL,
      title: 'Legal Fees',
    },
  ],
  Appendix: [],
};

export const AllDynamicKeyName = {
  services: 'Services',
  standardsOfPerformance: 'Standards of Performance',
  generalProvisions: 'General Provisions',
  otherProvisions: 'Other Provisions',
  appendix: 'Appendix',
};

export const RationalOptions = {
  'I want to invite this company on a project': 1,
  'I want to add the company to my panel': 2,
  'Just for your information': 3,
};

//TODO: Make sure to update whenever Improveo CommunityImpactList is updated
export const ImproveoCompanyCommunityImpactList = [
  {
    _id: '0',
    name: 'Offers Pro Bono Services',
  },
  {
    _id: '1',
    name: 'Offers Discounts for NGOs',
  },
];

//TODO: Make sure to update whenever Improveo Diversity is updated
export const ImproveoCompanyDiversityList = [
  {
    _id: 'Woman Owned',
    name: 'Woman Owned',
  },
  {
    _id: 'Veteran Owned',
    name: 'Veteran Owned',
  },
  {
    _id: 'Minority Owned',
    name: 'Minority Owned',
  },
  {
    _id: 'LGBTQ Owned',
    name: 'LGBTQ Owned',
  },
  {
    _id: 'Disabled Owned',
    name: 'Disabled Owned',
  },
];

export const urlBeginsWithHttp = /^(http|https):/;
export enum GeneralInfoTabs {
  ABOUT = 'about',
  OFFICES = 'offices',
  PUBLICATIONS = 'publications',
  OFFERINGS = 'offerings',
  CASE_STUDY = 'caseStudy',
}

export enum OfferingSectionsName {
  ConsultingIndustries = 'Consulting Solutions By Industry',
  ConsultingCapabilities = 'Consulting Solutions By Capability',
  DigitalIndustires = 'Digital Solutions By Industry',
  DigitalCapabilities = 'Digital Solutions By Capability',
}

export const HtmlListTagsMatch = /(<([(ul|ol|li|/a)>]+)>)/g;
export const HtmlImgTagMatch = /(<img)/g;
export const HtmlBackQuoteMatch = /(<(blockquote)>)/g;

export const HelpRedirectLink = 'https://support.consource.io';

export const InvoicingTable = [
  { columnDef: 'invoiceNumber', header: ' #', cssSelector: 'i', sort: false, key: 'invoiceNumber', type: 'text' },
  { columnDef: 'projectName', header: 'PROJECT', cssSelector: 'project-name', sort: true, key: 'projectName', type: 'textWithIcon' },
  { columnDef: 'firmName', header: 'PROVIDER', cssSelector: 'firm-name', sort: true, key: 'firmName', type: 'photoName', photoKey: 'firmPhoto' },
  { columnDef: 'companyName', header: 'COMPANY', cssSelector: 'company-name', sort: true, key: 'companyName', type: 'photoName', photoKey: 'companyPhoto' },
  { columnDef: 'invoice', header: 'INVOICING MILESTONE', cssSelector: 'invoice', sort: true, key: 'invoice', type: 'text' },
  // { columnDef: 'invoiceDoc', header: 'INVOICE', cssSelector: 'invoice', sort: true, key: 'invoiceDocument', type: 'invoiceDoc' }, // Moved this column to actions
  { columnDef: 'actualAmount', header: 'ACTUAL AMOUNT', cssSelector: 'act-amount', sort: true, key: 'actualAmount', type: 'textWithSymbol' },
  { columnDef: 'submittedOn', header: 'SUMITTED ON', cssSelector: 'submitted-on', sort: true, key: 'submittedOn', type: 'date' },
  { columnDef: 'approvalStatus', header: 'APPROVAL STATUS', cssSelector: 'approval-status', sort: false, type: 'invoiceApprovalStatus', key: 'status' },
  { columnDef: 'targetPaymentDate', header: 'TARGET PAYMENT DATE', cssSelector: 'target-payment-date', sort: false, key: 'targetPaymentDate', type: 'date' },
  { columnDef: 'paymentStatus', header: 'PAYMENT STATUS', cssSelector: 'approval-status', sort: false, key: 'paymentStatus', type: 'paymentStatus' },
  { columnDef: 'paymentDate', header: 'DATE', cssSelector: 'payment-date', sort: false, key: 'paymentDoneOn', type: 'date' },
  { columnDef: 'invoiceActions', header: 'ACTION', cssSelector: 'delete', sort: false, type: 'invoiceActions', key: 'projectId' },
];
// Need to verify
// { columnDef: 'estAmount', header: 'Estimated Amount', cssSelector: 'est-amount', sort: true, key: 'amount', type: 'text' },
// { columnDef: 'estDate', header: 'Estimated Date', cssSelector: 'est-date', sort: true, key: 'estimatedDate', type: 'date' },
// { columnDef: 'invoiceDoc', header: 'Invoice_', cssSelector: 'invoice', sort: true, key: 'invoiceDocument', type: 'invoiceDoc' },
// { columnDef: 'actualDate', header: 'Actual Date', cssSelector: 'act-date', sort: true, key: 'actualDate', type: 'date' },
// { columnDef: 'submittedBy', header: 'By', cssSelector: 'submitted-by', sort: true, key: 'submittedBy', type: 'photoName', photoKey: 'submittedByPhoto' },
// { columnDef: 'approvedBy', header: 'By', cssSelector: 'approval-by', sort: true, key: 'approvedBy', type: 'photoName', photoKey: 'approvedByPhoto' },
// { columnDef: 'approvedOn', header: 'Date', cssSelector: 'approval-date', sort: true, key: 'approvedOn', type: 'date' },
// { columnDef: 'paymentBy', header: 'By', cssSelector: 'payment-by', sort: false, key: 'paymentDoneBy', type: 'text' },

export const ProjectManagementInvoicingTable = [
  { columnDef: 'invoiceNumber', header: '#', cssSelector: '', sort: false, key: 'invoiceNumber', type: 'text' },
  { columnDef: 'invoice', header: 'INVOICING MILESTONE', cssSelector: 'invoice', sort: false, key: 'invoice', type: 'textWithDiff' },
  // {
  //   columnDef: 'invoiceDocProjectManagement',
  //   header: 'INVOICE',
  //   cssSelector: 'invoice',
  //   sort: false,
  //   key: 'invoiceDocument',
  //   type: 'invoiceDocProjectManagement',
  // },
  { columnDef: 'actualAmount', header: 'ACTUAL AMOUNT', cssSelector: 'act-amount', sort: true, key: 'actualAmountInInvoiceCurrency', type: 'actualAmount' },
  { columnDef: 'submittedOn', header: 'SUBMITTED ON', cssSelector: 'submitted-on', sort: true, key: 'submittedOn', type: 'date' },
  {
    columnDef: 'approvalStatus',
    header: 'INVOICE STATUS',
    cssSelector: 'approval-status',
    sort: false,
    type: 'invoiceApprovalStatusProjectManagement',
    key: 'status',
  },
  { columnDef: 'targetPaymentDate', header: 'TARGET PAYMENT DATE', cssSelector: 'target-payment-date', sort: false, key: 'targetPaymentDate', type: 'date' },
  {
    columnDef: 'paymentStatusProjectManagement',
    header: 'PAYMENT STATUS',
    cssSelector: 'approval-status',
    sort: false,
    key: 'paymentStatus',
    type: 'paymentStatusProjectManagement',
  },
  { columnDef: 'paymentDate', header: 'DATE', cssSelector: 'payment-date', sort: false, key: 'paymentDoneOn', type: 'date' },
  {
    columnDef: 'source',
    header: 'CHANGE SOURCE',
    cssSelector: '',
    sort: false,
    type: 'changeSource',
    key: 'sourceChangeId',
  },
  {
    columnDef: 'destination',
    header: 'CHANGE DESTINATION',
    cssSelector: '',
    sort: false,
    type: 'changeDestination',
    key: 'destinationChangeId',
  },
  {
    columnDef: 'changeStatus',
    header: 'CHANGE STATUS',
    cssSelector: 'approval-status',
    sort: false,
    type: 'changeStatus',
    key: 'destinationChangeId',
  },
  {
    columnDef: 'invoiceActionsProjectManagement',
    header: 'ACTION',
    cssSelector: 'delete',
    sort: false,
    type: 'invoiceActionsProjectManagement',
    key: 'projectId',
  },
];
// Need to verify
// { columnDef: 'estAmount', header: 'Estimated Amount', cssSelector: 'est-amount', sort: true, key: 'amount', type: 'text' },
// { columnDef: 'projectCurrency', header: 'Company Currency', cssSelector: '', sort: false, key: 'companyCurrency', type: 'text' },
// { columnDef: 'estDate', header: 'Estimated Date', cssSelector: 'est-date', sort: true, key: 'estimatedDate', type: 'date' },
// { columnDef: 'actualDate', header: 'Actual Date', cssSelector: 'act-date', sort: true, key: 'actualDate', type: 'date' },
// { columnDef: 'submittedBy', header: 'By', cssSelector: 'submitted-by', sort: true, key: 'submittedBy', type: 'photoName', photoKey: 'submittedByPhoto' },
// { columnDef: 'approvedBy', header: 'By', cssSelector: 'approval-by', sort: true, key: 'approvedBy', type: 'photoName', photoKey: 'approvedByPhoto' },
// { columnDef: 'approvedOn', header: 'Date', cssSelector: 'approval-date', sort: true, key: 'approvedOn', type: 'date' },
// { columnDef: 'paymentBy', header: 'By', cssSelector: 'payment-by', sort: false, key: 'paymentDoneBy', type: 'text' },

export enum AdminSettingsDrawerType {
  LANGUAGES,
  CONVERSION,
  MANAGEMENT,
  FILE_FORMAT,
  ACTIONS,
  COUNTRIES,
}

export const CurrencyConversion = [
  { columnDef: 'currency', header: 'Currency', cssSelector: 'currency', sort: true, key: 'currencyCode', type: 'CurrencySymbol' },
  { columnDef: 'conversion', header: 'Conversion Rate', cssSelector: 'conversion', sort: true, key: 'conversionValue', type: 'textWithEdit' },
  { columnDef: 'validity', header: 'validity Date', cssSelector: 'valid-date', sort: true, key: 'validity', type: 'date' },
  { columnDef: 'currencyActions', header: 'Action', cssSelector: 'edit', sort: false, type: 'currencyActions', key: '_id' },
];

export const ChangesHistoryTable = [
  { columnDef: 'tabName', header: 'TAB NAME', cssSelector: 'tab-name', sort: false, key: 'fieldKey', type: 'historyTabName' },
  { columnDef: 'fieldName', header: 'FIELD NAME', cssSelector: 'field-name', sort: false, key: 'fieldKey', type: 'historyFieldName' },
  { columnDef: 'updatedBy', header: 'CHANGED BY', cssSelector: 'changed-by', sort: false, type: 'nameWithDetailCard', key: 'updatedBy' },
  { columnDef: 'updatedOn', header: 'CHANGED ON', cssSelector: 'changed-on', sort: false, key: 'updatedOn', type: 'date' },
  { columnDef: 'status', header: 'STATUS', cssSelector: 'status', sort: false, key: 'isValidated', type: 'pmHistoryStatus' },
];

export const ColorTableColumn = [
  { columnDef: 'colorName', header: 'NAME', cssSelector: 'color-name', sort: false, type: 'colorName', key: 'name', },
  { columnDef: 'colorHax', header: 'COLOR', cssSelector: 'color', sort: false,  type: 'colorHax', key: 'color' },
  { columnDef: 'colorCreatedBy', header: 'CREATED BY', cssSelector: 'changed-by', sort: false, type: 'colorCreatedBy', key: 'createdBy' },
  { columnDef: 'colorCreatedON', header: 'CREATED ON', cssSelector: 'changed-on', sort: false, type: 'colorCreatedON', key: 'createdOn' },
  { columnDef: 'colorUpdatedBy', header: 'CHANGED BY', cssSelector: 'changed-by', sort: false, type: 'colorUpdatedBy', key: 'updatedBy' },
  { columnDef: 'colorUpdatedOn', header: 'CHANGED ON', cssSelector: 'changed-on', sort: false, type: 'colorUpdatedOn', key: 'updatedAt' },
  {
    columnDef: 'colorAction',
    header: 'ACTION',
    cssSelector: 'color-action',
    sort: false,
    type: 'colorAction',
    key: '',
  },
];

export const PitchTableColumn = [
  { columnDef: 'round', header: 'ROUND', cssSelector: 'round', sort: false, type: 'round', key: 'round', },
  { columnDef: 'pitch_title', header: 'PITCH TITLE', cssSelector: 'pitch_title', sort: false,  type: 'pitch_title', key: 'meetingId' },
  { columnDef: 'pitch_time', header: 'PITCH TIME', cssSelector: 'pitch_time', sort: false, type: 'pitch_time', key: 'meetingId' },
];

export enum UpdateStatus {
  Initial,
  Success,
  Failed,
}

export const SuperAdminClientTab = {
  billing: 'Billing',
  'accounts-billing': 'Account & Billing',
  'module-management': 'Module Management',
  activity: 'Activity',
};

export enum SuperAdminClientsTab {
  Billing = 'billing',
  Account = 'account',
  ModuleManagement = 'module-management',
}

export enum ClientAccountTypes {
  Platform = 'platform',
  Marketplace = 'marketplace',
  AdministrativeInfo = 'Billing Info',
  FirmContract = 'Firm Contract',
}

export const clientAccountTypeForQuery = {
  Plan: 'plan',
  Contract: 'contract',
  'Billing Info': 'administrativeInfo',
};

export const PlanOptions = ['Subscription'];
export const PlanNatures = ['Trial', 'Regular', 'Managed services'];
export const TimePeriodOptions = {
  '12 Months': 12,
};
export const RenewalTypeOptions = { 'Auto Renewal': 'autoRenewal' };

export const AlphaNumericRegex = /^[a-zA-Z0-9]*$/;

export const zeroToHundredWithDecimalsRegex = /^(((([1-9][0-9]?)|(100))(\.\d+)?)|(0.[1-9][0-9]*))$/;

export const nonZeroNumbers = '^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$';

export const DefaultAccountPlan = {
  planType: 'Subscription',
  poNumber: '',
  startDate: '',
  endDate: '',
  timePeriod: 12,
  renewalType: 'autoRenewal',
};

export const DefaultAccountAdministrativeInfo = {
  accountCreatedDate: '',
  adminEmail: '',
  billingInfo: {
    billingContact: '',
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    },
  },
  applicableTax: '',
};

export enum InvoiceStatus {
  OPEN = 'open',
  PAID = 'paid',
  DRAFT = 'draft',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
  PAID_OUT_OF_BAND = 'paid_out_of_band',
}
export const NotificationContentTemplatesColumns = [
  { columnDef: 'templateName', header: 'TEMPLATE NAME', cssSelector: 'element-name', sort: true, key: 'templateName', type: 'text' },
  { columnDef: 'iconName', header: 'ICON', cssSelector: '', sort: false, key: 'iconName', type: 'icon' },
  { columnDef: 'categoryName', header: 'CATEGORY', cssSelector: '', sort: false, key: 'categoryName', type: 'text' },
  { columnDef: 'language', header: 'LANGUAGE', cssSelector: '', sort: false, key: 'languageName', type: 'titleCaseText' },
  { columnDef: 'editNotificationContent', header: 'ACTION', cssSelector: '', sort: false, type: 'edit' }, //TODO
];

export enum BoardingStatus {
  Start = 'start',
  OnGoing = 'onGoing',
  Approved = 'approved',
}

export enum OnBoardingEditDrawerType {
  AddSection,
  AddQuestion,
  EditSection,
  EditQuestion,
}

export enum OnBoardingAnswerType {
  Input = 'input',
  RadioButton = 'radioButton',
  Template = 'template',
  Upload = 'upload',
}

export enum MsaAnswerType {
  Input = 'quill',
  Template = 'template',
  Upload = 'upload',
}

export const OnBoardingKeyType = {
  0: 'questions',
  1: 'question',
  2: 'question',
  3: 'question',
};

export const OnBoardingDrawerTitle = {
  0: 'Add Section',
  1: 'Add Question',
  2: 'Edit Section',
  3: 'Edit Question',
};

export const OnBoardingVisibility = {
  0: { isSectionEditable: true, isSectionVisible: true, isQuestionVisible: false, isMultipleQuestions: false },
  1: { isSectionEditable: false, isSectionVisible: true, isQuestionVisible: true, isMultipleQuestions: false },
  2: { isSectionEditable: true, isSectionVisible: true, isQuestionVisible: false, isMultipleQuestions: false },
  3: { isSectionEditable: false, isSectionVisible: true, isQuestionVisible: true, isMultipleQuestions: false },
};

export const DefaultDrawerDetails = {
  0: { sectionName: '' },
  1: { sectionName: '', questions: [{ question: '', answerType: '' }] },
};

export const ClientOnBoardingTable = [
  { columnDef: 'client', header: 'Clients', cssSelector: 'element-name', sort: false, key: 'name', type: 'text', tip: '' },
  { columnDef: 'companyName', header: 'Company Name', cssSelector: 'client', sort: false, key: 'companyName', type: 'text', tip: '' },
  { columnDef: 'project', header: 'No of Projects', cssSelector: 'project', sort: false, key: 'projectCount', type: 'text', tip: '' },
  { columnDef: 'status', header: 'Status', cssSelector: 'approval-status', sort: false, key: 'onBoardingStatus', type: 'entityApprovalStatus', tip: '' },
  {
    columnDef: 'onBoardingActions',
    header: 'ACTION',
    cssSelector: 'edit',
    sort: false,
    type: 'viewOnboardingData',
    key: { entityId: 'entityId', clientId: 'clientId' },
    tip: 'View Onboarding Data',
  },
];

export const ChatSocketEvents = {
  SendMessage: 'send_message',
  IncomingMessage: 'incoming_message',
  RoomUnreadCount: 'room_unread_count',
  UserUnreadCount: 'user_unread_count',
  OnlineStatus: 'online_status',
};

export const RequestChangeStatus = ['approved', 'change-request'];

export enum chatType {
  'MESSAGE' = 1,
  'DOCUMENT' = 2,
}

export const ALLOWED_FILES_TYPES = ['.pdf', '.jpeg', '.png', '.xls', '.xlsx', '.doc', '.docx'];

export enum LINKS_ACTION_TYPE {
  'CREATE' = 1,
  'UPDATE' = 2,
  'DELETE' = 3,
  'CLOSE' = 4,
}

export enum DOCUMENT_TYPE {
  'LINK' = 1,
  'DOCUMENT' = 2,
}

export const WorspackeDrawerType = {
  TASK: 'tasks',
  LINK: 'links',
  Meetings: 'meetings',
  FILES: 'files'
};

export const AddMeetingNatureType = {
  'Project Workspace': 'projectWorkspace',
  Provider: 'consultingFirm',
};

export const ClientRiskTable = [
  { columnDef: 'riskLeName', header: 'RISK NAME', cssSelector: 'element-name', sort: false, key: 'name', type: 'text', tip: '' },
  { columnDef: 'riskName', header: 'RISK CATEGORY', cssSelector: 'element-name', sort: false, key: 'category', type: 'text', tip: '' },
  { columnDef: 'riskLight', header: 'RISK LEVEl', cssSelector: 'client', sort: false, key: 'light', type: 'riskLevel', tip: '' },
  { columnDef: 'likelihood', header: 'LIKELIHOOD', cssSelector: 'client', sort: false, key: 'likelihood', type: 'riskLevel', tip: '' },
  { columnDef: 'impact', header: 'IMPACT', cssSelector: 'client', sort: false, key: 'impact', type: 'riskLevel', tip: '' },
  { columnDef: 'riskCreatedBy', header: 'CREATED BY', cssSelector: 'client', sort: false, key: 'createdBy', type: 'userName', tip: '' },
  { columnDef: 'riskcreatedDate', header: 'DATE', cssSelector: 'client', sort: false, key: 'createdAt', type: 'date', tip: '' },
  { columnDef: 'riskUpdatedBy', header: 'LAST MODIFIED BY', cssSelector: 'client', sort: false, key: 'updatedBy', type: 'userName', tip: '' },
  { columnDef: 'riskUpdatedDate', header: 'DATE', cssSelector: 'client', sort: false, key: 'lastUpdated', type: 'date', tip: '' },
  {
    columnDef: 'riskAction',
    header: 'ACTION',
    cssSelector: 'delete',
    sort: false,
    type: 'riskAction',
    key: '_id',
    tip: 'View',
  },
];

export const ClientArchivedRiskTable = [
  { columnDef: 'riskLeName', header: 'RISK NAME', cssSelector: 'element-name', sort: false, key: 'name', type: 'text', tip: '' },
  { columnDef: 'riskName', header: 'RISK CATEGORY', cssSelector: 'element-name', sort: false, key: 'category', type: 'text', tip: '' },
  { columnDef: 'riskLight', header: 'LEVEL', cssSelector: 'client', sort: false, key: 'light', type: 'riskLevel', tip: '' },
  { columnDef: 'likelihood', header: 'LIKELIHOOD', cssSelector: 'client', sort: false, key: 'likelihood', type: 'riskLevel', tip: '' },
  { columnDef: 'impact', header: 'IMPACT', cssSelector: 'client', sort: false, key: 'impact', type: 'riskLevel', tip: '' },
  { columnDef: 'riskComment', header: 'COMMENT', cssSelector: 'element-name', sort: false, key: 'comments', type: 'text', tip: '' },
  { columnDef: 'riskCreatedBy', header: 'CREATED BY', cssSelector: 'client', sort: false, key: 'createdBy', type: 'userName', tip: '' },
  { columnDef: 'riskcreatedDate', header: 'DATE', cssSelector: 'client', sort: false, key: 'createdAt', type: 'date', tip: '' },
  { columnDef: 'riskUpdatedBy', header: 'LAST MODIFIED BY', cssSelector: 'client', sort: false, key: 'updatedBy', type: 'userName', tip: '' },
  { columnDef: 'riskUpdatedDate', header: 'DATE', cssSelector: 'client', sort: false, key: 'lastUpdated', type: 'date', tip: '' },
  {
    columnDef: 'riskUnArchive',
    header: 'ACTION',
    cssSelector: 'unArchive',
    sort: false,
    type: 'riskUnArchive',
    key: '_id',
    tip: 'UnArchive',
  },
];

export const LightList = [
  {
    value: 1,
    label: 'Red',
  },
  {
    value: 2,
    label: 'Amber',
  },
  {
    value: 3,
    label: 'Green',
  },
];

export const riskLevel = [
  {
    value: 'red',
    label: 'Red',
  },
  {
    value: 'amber',
    label: 'Amber',
  },
  {
    value: 'green',
    label: 'Green',
  },
];

export const LikelihoodList = [
  {
    value: 1,
    label: 'Red',
  },
  {
    value: 2,
    label: 'Green',
  },
];

export const LightListPriority = {
  high: 1,
  medium: 2,
  low: 3,
};

export const TemplateDrawerType = {
  DEMAND: 'demand',
  LEGAL_ENTITY: 'legalEntity',
};

export const FieldKeyBasedOnType = {
  quill: 'text',
  upload: 'document',
  template: 'template',
};

export enum MSAFieldType {
  TEXT,
  UPLOAD,
  TEMPLATE,
}

export enum RfpTabs {
  OVERVIEW = 0,
  PURPOSE = 1,
  BACKGROUND = 2,
  CONTEXT = 3,
  SCOPE_DELIVERABLES = 4,
  SCHEDULE_BUDGET = 5,
  OTHER_REQUIREMENTS = 6,
  PROPOSAL_CONTENT = 7,
  RFP_PROCESS = 8,
  ASSOCIATED_DOCS = 9,
}

export const AdminRiskTable = [
  { columnDef: 'riskName', header: 'RISK CATEGORY', cssSelector: 'element-name', sort: false, key: 'name', type: 'text', tip: '' },
  { columnDef: 'riskDefault', header: 'DEFAULT', cssSelector: 'riskDefault', sort: false, key: 'isDefault', type: 'radio', tip: '' },
  { columnDef: 'riskMandatory', header: 'MANDATORY', cssSelector: 'riskMandatory', sort: false, key: 'isMandatory', type: 'radio', tip: '' },
  { columnDef: 'riskcreatedDate', header: 'CREATED', cssSelector: 'riskcreatedDate', sort: false, key: 'createdAt', type: 'date', tip: '' },
  { columnDef: 'riskUpdatedDate', header: 'UPDATED', cssSelector: 'riskUpdatedDate', sort: false, key: 'updatedAt', type: 'date', tip: '' },
  {
    columnDef: 'editDelete',
    header: 'ACTION',
    cssSelector: 'delete',
    sort: false,
    type: 'editDelete',
    key: '_id',
    tip: '',
  },
];

export const OnboardingQuestionTable = [
  { columnDef: 'questionnaireName', header: 'QUESTIONNAIRE', cssSelector: 'element-name', sort: false, key: 'templateName', type: 'text', tip: '' },
  { columnDef: 'validationProgress', header: 'VALIDATION PROGRESS', cssSelector: '', sort: false, key: 'progress', type: 'text', tip: '' },
  { columnDef: 'viewQuestionnaire', header: 'ACTION', cssSelector: 'view', sort: false, type: 'view', key: '_id', tip: 'View Questionnaire' },
];

export const allowedFileTypes = ['pdf', 'xlsx', 'jpg', 'jpeg', 'png', 'xls', 'csv', 'ppt', 'pptx', 'doc', 'docx'];

export const styleSections = [
  {
    sectionName: 'Text Style',
    sectionKey: 'textStyle',
    fields: [
      {
        fieldName: 'Page Title',
        fieldKey: 'pageTitle',
        icon: 'H1'
      },
      {
        fieldName: 'Section Title',
        fieldKey: 'sectionTitle',
        icon: 'H2'
      },
      {
        fieldName: 'Sub Section Title',
        fieldKey: 'subSectionTitle',
        icon: 'H3'
      },
      {
        fieldName: 'Sub Topic Title',
        fieldKey: 'subTopicTitle',
        icon: 'H4'
      },
      {
        fieldName: 'Content',
        fieldKey: 'contentStyle',
        icon: 'P'
      },
    ]
  },
  {
    sectionName: 'Margin Style',
    sectionKey: 'marginStyle',
    fields: [
      {
        fieldName: 'Footer Margin',
        fieldKey: 'footerMargin',
        icon: 'F'
      }
    ]
  }
];

export const StyleType = {
  PAGE_TITLE: 'pageTitle',
  SECTION_TITLE: 'sectionTitle',
  SUBSECTION_TITLE: 'subSectionTitle',
  SUB_TOPIC_TITLE: 'subTopicTitle',
  CONTENT_STYLE: 'contentStyle',
  FOOTER_MARGIN: 'footerMargin'
};

export const DefaultStyleText = {
  pageTitle: 'This is a Sample Text Preview for Title',
  sectionTitle: '1. This is a Sample text Preview for Sections',
  subSectionTitle: '1.1. This is a Sample text Preview for Sub-Sections',
  subTopicTitle: '1.1.1. This is a Sample text Preview for Sub-Sub-Sections',
  contentStyle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const Colors = [
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
];

export const FontSize = [
  {
    label: '10px',
    value: '10px',
  },
  {
    label: '12px',
    value: '12px',
  },
  {
    label: '14px',
    value: '14px',
  },
  {
    label: '16px',
    value: '16px',
  },
  {
    label: '18px',
    value: '18px',
  },
  {
    label: '20px',
    value: '20px',
  },
  {
    label: '22px',
    value: '22px',
  },
  {
    label: '24px',
    value: '24px',
  },
];

export const FontFamily = [
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Lato',
    value: 'Lato',
  },
  {
    label: 'Noto Sans',
    value: 'NotoSans',
  },
  {
    label: 'Raleway',
    value: 'Raleway',
  },
  {
    label: 'Source Sans Pro',
    value: 'SourceSansPro',
  },
  {
    label: 'PT Sans',
    value: 'PTSans',
  },
  {
    label: 'Merriweather',
    value: 'Merriweather',
  },
  {
    label: 'Roboto Slab',
    value: 'RobotoSlab',
  },
  {
    label: 'Poppins',
    value: 'Poppins',
  },
  {
    label: 'Inter',
    value: 'Inter',
  },
];

export const Style = [
  {
    label: 'Bold',
    value: 'bold',
  },
  {
    label: 'Italic',
    value: 'italic',
  },
  {
    label: 'Underline',
    value: 'underline',
  },
];

export enum UPLOAD {
  FILE_FORMAT = 'Fileformat',
  FILE_LIMIT = 'FileLimits',
}

export const PAGINATION = {
  DEFAULT_PAGE: 0,
  DEFAULT_LIMIT: 10,
};

export enum DisplayEditableFields {
  HEADING = 'heading',
  BUSINESS_UNIT = 'business-units',
  FUNCTIONS = 'functions',
  COST_CENTERS = 'cost-centers',
  SUB_SECTION_HEADING = 'sub-section-heading',
}

export const ORG_ATTRIBUTE = {
  'business-units': 'businessUnit',
  functions: 'functions',
  'cost-centers': 'costCenters',
};

export const steps = [
  {
    step: '1',
    name: 'Demand',
  },
  {
    step: '2',
    name: 'RFP',
  },
  {
    step: '3',
    name: 'Sourcing & Invitation',
  },
  {
    step: '4',
    name: 'Briefing',
  },
  {
    step: '5',
    name: 'Selection',
  },
  {
    step: '6',
    name: 'SOW',
  },
  {
    step: '7',
    name: 'Project Management',
  },
  {
    step: '8',
    name: 'Performance',
  },
];

export const MarketPlaceProjectFilter = steps.filter(
  step => step.step === '7' || step.step === '8'
);

export const ClientOnboardingStatus = {
  pending: 'Pending',
  on_going: 'On Going',
  active: 'Active',
  off_boarding: 'Off Boarding',
  inactive: 'Inactive',
};

export const ConsourceStatus = {
  Pending: 'pending',
  Active: 'active',
  Inactive: 'inactive',
};

export const providerOnboardingStatus = {
  on_going: 'on Going',
  done: 'done',
  off_boarding: 'off Boarding',
};

export const OnboardingStatus = [
  {
    label: 'Ongoing',
    value: 'on_going',
  },
  {
    label: 'Done',
    value: 'done',
  },
  {
    label: 'Off Boarding',
    value: 'off_boarding',
  },
];

export const LegalEntityOnboardingStatus = [
  {
    label: 'Ongoing',
    value: 'onGoing',
  },
  {
    label: 'Done',
    value: 'done',
  },
  {
    label: 'Off Boarding',
    value: 'offboarding',
  },
];

export const LEOnboardingStatus = [
  {
    label: 'Ongoing',
    value: 'onGoing',
  },
  {
    label: 'Done',
    value: 'done',
  },
  {
    label: 'Off Boarding',
    value: 'offboarding',
  },
];

export const StatusTypeForProvider = {
  consource: 1,
  cgn: 2,
  onboarding: 3,
};

export const ClientOnboardingStatusArray = ['Pending', 'Active', 'On Going', 'Off Boarding', 'Inactive'];

export const StripeBaseUrl = 'https://dashboard.stripe.com/test';

const ClientAdminChangeRequestStatus = { Pending: 'initiated', Accepted: 'accepted', Rejected: 'rejected' };
const ClientAdminChangeRequestStatusColors = { initiated: 'tag-pending', accepted: 'tag-accepted', rejected: 'tag-rejected' };

export const AdminChangeRequestTable = [
  {
    columnDef: 'companyName',
    header: 'CLIENT NAME',
    cssSelector: 'element-name',
    sort: true,
    key: 'companyName',
    photoKey: 'companyPhoto',
    type: 'photoName',
    tip: '',
  },
  {
    columnDef: 'clientName',
    header: 'ADMIN NAME',
    cssSelector: 'element-name',
    sort: true,
    key: 'clientName',
    photoKey: 'clientPhoto',
    type: 'photoName',
    tip: '',
  },
  { columnDef: 'userName', header: 'USER NAME', cssSelector: 'element-name', sort: true, key: 'userName', photoKey: 'userPhoto', type: 'photoName', tip: '' },
  {
    columnDef: 'status',
    header: 'STATUS',
    cssSelector: 'status',
    sort: false,
    key: 'status',
    type: 'status',
    statusArray: ClientAdminChangeRequestStatus,
    statusClass: ClientAdminChangeRequestStatusColors,
    tip: '',
  },
  { columnDef: 'createdAt', header: 'REQUESTED ON', cssSelector: 'requested-on', sort: true, key: 'createdAt', type: 'date', tip: '' },
  {
    columnDef: 'acceptReject',
    header: 'ACTION',
    cssSelector: 'acceptReject',
    sort: false,
    type: 'acceptRejectAction',
    pendingStatusKey: 'initiated',
    key: '_id',
  },
];

export const ProviderAdminChangeRequestTable = [
  {
    columnDef: 'companyName',
    header: 'FIRM NAME',
    cssSelector: 'element-name',
    sort: true,
    key: 'firmName',
    photoKey: 'companyPhoto',
    type: 'photoName',
    tip: '',
  },
  {
    columnDef: 'clientName',
    header: 'ADMIN NAME',
    cssSelector: 'element-name',
    sort: true,
    key: 'adminName',
    photoKey: 'clientPhoto',
    type: 'photoName',
    tip: '',
  },
  { columnDef: 'userName', header: 'USER NAME', cssSelector: 'element-name', sort: true, key: 'userName', photoKey: 'userPhoto', type: 'photoName', tip: '' },
  {
    columnDef: 'status',
    header: 'STATUS',
    cssSelector: 'status',
    sort: false,
    key: 'status',
    type: 'status',
    statusArray: ClientAdminChangeRequestStatus,
    statusClass: ClientAdminChangeRequestStatusColors,
    tip: '',
  },
  { columnDef: 'createdAt', header: 'REQUESTED ON', cssSelector: 'requested-on', sort: true, key: 'createdAt', type: 'date', tip: '' },
  {
    columnDef: 'acceptReject',
    header: 'ACTION',
    cssSelector: 'acceptReject',
    sort: false,
    type: 'acceptRejectAction',
    pendingStatusKey: 'initiated',
    key: '_id',
  },
];

export enum AdminRoleChangeStatus {
  INITIATED = 'initiated',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export const AdminChangeStatus = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Pending',
    value: 'initiated',
  },
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

export enum AdminPageType {
  LANGUAGE = 'language',
  CURRENCY = 'currency',
  TAXONOMY = 'taxonomy',
  NDA = 'nda',
  MSA = 'msa',
  RFP = 'rfp',
  SOW = 'sow',
  UPLOADS = 'message-settings',
  QUESTIONNAIRE = 'legal-entity',
  RISK = 'risk',
  SRM = 'srm',
  COUNTRY = 'countries',
  MARKET_PLACE = 'market-place',
  TAX_INFO = 'tax-info',
}

export const AdminSettingPageTitle = {
  [AdminPageType.LANGUAGE]: { title: 'LANGUAGES', subtitle: 'Manage global-level Language settings here.' },
  [AdminPageType.CURRENCY]: { title: 'CURRENCIES', subtitle: 'Manage global-level Currency settings here.' },
  [AdminPageType.TAXONOMY]: { title: 'TAXONOMY', subtitle: 'Manage global-level Taxonomy settings here.' },
  [AdminPageType.NDA]: { title: 'NDA', subtitle: 'Manage global-level Non Disclosure Agreement (NDA) settings here.' },
  [AdminPageType.MSA]: { title: 'MSA', subtitle: 'Manage global-level Master Service Agreement (MSA) settings here.' },
  [AdminPageType.RFP]: { title: 'RFP', subtitle: 'Manage global-level Request For Proposal (RFP) settings here.' },
  [AdminPageType.SOW]: { title: 'SOW', subtitle: 'Manage global-level Statement Of Work (SOW) settings here.' },
  [AdminPageType.UPLOADS]: { title: 'DOCUMENTS', subtitle: 'Manage global-level Document settings here.' },
  [AdminPageType.QUESTIONNAIRE]: { title: 'KYS QUESTIONNAIRES', subtitle: 'Manage global-level KYS Questionnaire settings here.' },
  [AdminPageType.RISK]: { title: 'RISKS', subtitle: 'Manage global-level Risk settings here' },
  [AdminPageType.SRM]: { title: 'SUPPLIER RELATIONSHIP MANAGEMENT', subtitle: 'Manage global-level Supplier Relationship Management (SRM) settings here.' },
  [AdminPageType.COUNTRY]: { title: 'TAXONOMY', subtitle: 'Manage global-level Taxonomy settings here.' },
  [AdminPageType.MARKET_PLACE]: { title: 'MARKETPLACE', subtitle: 'Manage global-level marketplace settings here.' },
  [AdminPageType.TAX_INFO]: { title: 'TAX INFORMATION', subtitle: 'Manage Consource tax information settings here.' },
};

export const AdminSettingsRouteRegex = /\/dashboard\/adminSettings\/([^?/]+)/;

export const DemographyType = {
  INDUSTRY: 'industries',
  CAPABILITY: 'capabilities',
};

export enum ClientPageType {
  COMPANY_DETAILS = 'client-settings',
  TAXONOMY = 'client-demographics',
  NDA = 'nda',
  MSA = 'msa',
  DEMAND = 'demand',
  PIPELINE = 'pipeline',
  RFP = 'rfp',
  SOW = 'sow',
  PERFORMANCE = 'performance',
  UPLOADS = 'system-settings',
  QUESTIONNAIRE = 'legal-entity',
  RISK = 'risk',
  CLEAR = 'clear-data',
  CURRENCY = 'currency-rates',
}

export const ClientSettingPageTitle = {
  [ClientPageType.COMPANY_DETAILS]: { title: 'Company Details', subtitle: 'Manage your Company Details here' },
  [ClientPageType.CURRENCY]: { title: 'Currencies and Rates', subtitle: 'Manage your Currencies and Rates here' },
  [ClientPageType.TAXONOMY]: { title: 'Taxonomy', subtitle: 'Manage Taxonomy settings here' },
  [ClientPageType.NDA]: { title: 'NDA', subtitle: 'Manage Non Disclosure Agreement (NDA) settings here' },
  [ClientPageType.MSA]: { title: 'MSA', subtitle: 'Manage Master Service Agreement (MSA) settings here' },
  [ClientPageType.DEMAND]: { title: 'Demand', subtitle: 'Manage Demand settings here' },
  [ClientPageType.PIPELINE]: { title: 'Pipeline', subtitle: 'Manage Pipeline settings here' },
  [ClientPageType.RFP]: { title: 'RFP', subtitle: 'Manage Request For Proposal (RFP) settings here' },
  [ClientPageType.SOW]: { title: 'SOW', subtitle: 'Manage Statement Of Work (SOW) settings here' },
  [ClientPageType.PERFORMANCE]: { title: 'Performance', subtitle: 'Manage performance settings here' },
  [ClientPageType.UPLOADS]: { title: 'System Settings', subtitle: 'Manage your System Settings here' },
  [ClientPageType.QUESTIONNAIRE]: { title: 'Questionnaires', subtitle: 'Manage Questionnaire settings here' },
  [ClientPageType.RISK]: { title: 'Risk', subtitle: 'Manage Risk settings here' },
  [ClientPageType.CLEAR]: {
    title: 'Clear Data',
    subtitle:
      'Clean up your environment by deleting sandbox and other testing data. Data in sandbox are created by the application for trying out features when you have signed up for ConSource',
  },
};

export const ProviderSettingsRouteRegex = /\/invitation-dashboard\/customization\/([^?/]+)/;

export enum ProviderPageType {
  PROVIDER_DETAILS = 'provider-details',
  UPLOADS = 'system-settings',
}

export const ProviderSettingPageTitle = {
  [ProviderPageType.PROVIDER_DETAILS]: { title: 'Provider Details', subtitle: 'Manage your Provider Details here' },
  [ProviderPageType.UPLOADS]: { title: 'System Settings', subtitle: 'Manage System settings here' },
};

export const ProviderSettingsModuleObject = [
  {
    name: 'Provider Details',
    path: '/invitation-dashboard/customization/provider-details',
  },
  {
    name: 'System Settings',
    path: '/invitation-dashboard/customization/system-settings',
  },
  {
    name: 'Customization',
    path: '/invitation-dashboard/customization',
  },
];

export const ClientSettingsRouteRegex = /\/dashboard\/otherSettings\/([^?/]+)/;

export const ClientSettingsModuleObject = [
  {
    name: 'Invite Requests',
    path: '/dashboard/users/invite-requests',
  },
  {
    name: 'Users',
    path: '/dashboard/users',
  },
  {
    name: 'Customization',
    path: '/dashboard/otherSettings',
  },
];

export const ClientAdminSettingsModuleObject = [
  {
    name: 'Invite Requests',
    path: '/dashboard/users/invite-requests',
  },
  {
    name: 'Users',
    path: '/dashboard/users',
  },
];

export const FirmSettingsModuleObject = [
  {
    name: 'Invite Requests',
    path: '/invitation-dashboard/users/invite-requests',
  },
  {
    name: 'Users',
    path: '/invitation-dashboard/users',
  },
];

export const InvitationStatus = {
  status: 1,
  type: 'sendInvite',
};

export enum ProviderPageType {
  GENERAL_INFO = 'general-info',
  ABOUT = 'about',
  OFFICES = 'offices',
  PUBLICATIONS = 'publications',
  OFFERINGS = 'offerings',
  CASE_STUDY = 'case-studies',
  LEGAL_ENTITY = 'legal-entity',
  ACCOUNT = 'account',
  CONTRACTS = 'contracts',
}

export const ProviderPageTitle = {
  [ProviderPageType.GENERAL_INFO]: { title: 'General Info' },
  [ProviderPageType.ABOUT]: { title: 'About' },
  [ProviderPageType.OFFICES]: { title: 'Offices' },
  [ProviderPageType.PUBLICATIONS]: { title: 'Publications' },
  [ProviderPageType.OFFERINGS]: { title: 'Offerings' },
  [ProviderPageType.CASE_STUDY]: { title: 'Case Studies' },
  [ProviderPageType.LEGAL_ENTITY]: { title: 'Legal Entity' },
  [ProviderPageType.ACCOUNT]: { title: 'Account' },
  [ProviderPageType.CONTRACTS]: { title: 'Contracts' },
};

export const DEFAULT_PRECISION = 2;

export const ClientProviderPageRouteRegex = /\/dashboard\/provider\/viewProfile\/[^\/]+\/company-profile\/([^/?]+)/;
export const FirmProviderPageRouteRegex = /\/invitation-dashboard\/firm-profile\/[^\/]+\/company-profile\/([^/?]+)/;
export const AdminProviderPageRouteRegex = /\/dashboard\/consulting-firms\/viewProfile\/[^\/]+\/company-profile\/([^/?]+)/;

export enum EntityType {
  INFO = 'info',
  ONBOARDING = 'on-boarding',
  RISK = 'risk',
  QUESTION_SET = 'question-set',
  KYS_QUESTION_LIST = 'kys-question-list',
  KYS_QUESTION = 'kys-question',
}

export const EntityTitle = {
  [EntityType.INFO]: { title: 'Legal Entity Information' },
  [EntityType.ONBOARDING]: { title: 'Questionnaires' },
  [EntityType.QUESTION_SET]: { title: 'Questionnaires' },
  [EntityType.RISK]: { title: 'Risk' },
  [EntityType.KYS_QUESTION]: { title: 'KYS' },
  [EntityType.KYS_QUESTION_LIST]: { title: 'KYS' },
};

export const EntityRegex = /\/dashboard\/provider\/viewProfile\/[^\/]+\/company-profile\/([^/?]+)/;

export enum LEGAL_ENTITIES_TAB {
  ACCOUNT = 'ACCOUNT',
  BILLING = 'BILLING',
  CONTRACTS = 'CONTRACTS',
}

export enum EDIT_PROJECT_DRAWER_TYPE {
  EDIT_KEY_PROJECT_INFO,
  EDIT_TAXONOMY_INFO,
}

export enum SIGNING_PARTY {
  CLIENT = 1,
  FIRM = 2,
}

export const SignatureTabs = {
  SOW: 'SoW',
  NDA: 'NDA',
  MSA: 'MSA',
};

export enum ADD_PROVIDER_PANEL_FIELDS {
  PROVIDER,
  RELATIONSHIP_MANAGER,
  TIER,
  SEGEMNT,
}

export const CurrencyConversionApiUrl = 'open.er-api.com';

export enum CLIENT_MODULES {
  'HOME' = 'HOME',
  'PIPELINE_MANAGEMENT' = 'PIPELINE_MANAGEMENT',
  'PROJECTS' = 'PROJECTS',
  'CONTRACTS' = 'CONTRACTS',
  'PANEL_MANAGEMENT' = 'PANEL_MANAGEMENT',
  'KNOWLEDGE_CENTER' = 'KNOWLEDGE_CENTER',
  'CATEGORY_MANAGEMENT' = 'CATEGORY_MANAGEMENT',
  'TASKS' = 'TASKS',
  'ADMIN' = 'ADMIN',
  'SETTINGS' = 'SETTINGS',
  'INVITE_USER' = 'INVITE_USER',
  'HELP_CENTER' = 'HELP_CENTER',
  'LOG_OUT' = 'LOG_OUT',
  'PROVIDER_PROFILES' = 'PROVIDER_PROFILES',
  'ADD_TASK' = 'ADD_TASK',
  'MESSAGES' = 'MESSAGES',
  'NOTIFICATIONS' = 'NOTIFICATIONS',
  'STRATEGIC_PRIORITIES' = 'STRATEGIC_PRIORITIES',
  'PIPELINE' = 'PIPELINE',
  'APPROVAL_WORKFLOWS' = 'APPROVAL_WORKFLOWS',
  'DEMANDS' = 'DEMANDS',
  'PROJECT_LIST' = 'PROJECT_LIST',
  'PROJECT_ANALYTICS' = 'PROJECT_ANALYTICS',
  'CONTRACTS_LIST' = 'CONTRACTS_LIST',
  'INVOICES_AND_PAYMENTS' = 'INVOICES_AND_PAYMENTS',
  'PROVIDER_PANEL' = 'PROVIDER_PANEL',
  'LEGAL_ENTITIES' = 'LEGAL_ENTITIES',
  'PANEL_ANALYTICS' = 'PANEL_ANALYTICS',
  'PERFORMANCE_MANAGEMENT' = 'PERFORMANCE_MANAGEMENT',
  'C_G_N' = 'C_G_N',
  'SUGGESTED_PROVIDER' = 'SUGGESTED_PROVIDER',
  'RISK_MANAGEMENT' = 'RISK_MANAGEMENT',
  'LIBRARY' = 'LIBRARY',
  'SCS_ACADEMY' = 'SCS_ACADEMY',
  'COMMUNITY' = 'COMMUNITY',
  'COMPANY_ANALYTICS' = 'COMPANY_ANALYTICS',
  'OBJECTIVES' = 'OBJECTIVES',
  'PROCESS_ANALYTICS' = 'PROCESS_ANALYTICS',
  'CONSULTING_BUDGETS' = 'CONSULTING_BUDGETS',
  'TASK_ANALYTICS' = 'TASK_ANALYTICS',
  'ALL_TASK' = 'ALL_TASK',
  'MY_TASK' = 'MY_TASK',
  'USER_LIST' = 'USER_LIST',
  'USER_INVITE_REQUESTS' = 'USER_INVITE_REQUESTS',
  'ACCOUNT_BILLING' = 'ACCOUNT_BILLING',
  'ACTIVITY' = 'ACTIVITY',
  'CUSTOMISATION' = 'CUSTOMISATION',
  'PROVIDER_PROFILE_COMPANY_PROFILE' = 'PROVIDER_PROFILE_COMPANY_PROFILE',
  'PROVIDER_PROFILE_L_E' = 'PROVIDER_PROFILE_L_E',
  'PROVIDER_PROFILE_PARTNER' = 'PROVIDER_PROFILE_PARTNER',
  'PROVIDER_PROFILE_PROJECTS' = 'PROVIDER_PROFILE_PROJECTS',
  'PROVIDER_PROFILE_ANALYTICS' = 'PROVIDER_PROFILE_ANALYTICS',
  'PROVIDER_PROFILE_CONTRACTS' = 'PROVIDER_PROFILE_CONTRACTS',
  'PROVIDER_PROFILE_MEETINGS' = 'PROVIDER_PROFILE_MEETINGS',
  'PROVIDER_PROFILE_TASKS' = 'PROVIDER_PROFILE_TASKS',
  'PROVIDER_PROFILE_SETTINGS' = 'PROVIDER_PROFILE_SETTINGS',
  'NDA_WIZARD' = 'NDA_WIZARD',
  'MSA_WIZARD' = 'MSA_WIZARD',
  'ANNOUNCEMENTS_AREA' = 'ANNOUNCEMENTS_AREA',
  'SPEND_AREA' = 'SPEND_AREA',
  'OVERDUE_TASKS' = 'OVERDUE_TASKS',
  'LATEST_DEMANDS' = 'LATEST_DEMANDS',
  'LATEST_PROJECTS' = 'LATEST_PROJECTS',
  'LATEST_NOTIFICATIONS' = 'LATEST_NOTIFICATIONS',
  'LATEST_MESSAGES' = 'LATEST_MESSAGES',
  'LATEST_MEETTINGS' = 'LATEST_MEETTINGS',
  'LATEST_SOW' = 'LATEST_SOW',
  'END_PROJECT' = 'END_PROJECT',
  'EXPIRE_CONTRACT' = 'EXPIRE_CONTRACT',
  'LATEST_SURVEYS' = 'LATEST_SURVEYS',
  'PROMOTER_VS_PROVIDER_SCORE' = 'PROMOTER_VS_PROVIDER_SCORE',
  'USERS_ON_NAV' = 'USERS_ON_NAV',
  'PROJECT_PRICING_VISIBILITY' = 'PROJECT_PRICING_VISIBILITY'
}

export const ProviderProfilesTabCode = [
  'PROVIDER_PROFILE_COMPANY_PROFILE',
  'PROVIDER_PROFILE_L_E',
  'PROVIDER_PROFILE_PARTNER',
  'PROVIDER_PROFILE_PROJECTS',
  'PROVIDER_PROFILE_ANALYTICS',
  'PROVIDER_PROFILE_CONTRACTS',
  'PROVIDER_PROFILE_MEETINGS',
  'PROVIDER_PROFILE_TASKS',
  'PROVIDER_PROFILE_SETTINGS',
];

export const ProviderProfileTabs = {
  PROVIDER_PROFILE_COMPANY_PROFILE: {
    routeName: 'Company Profile',
    routeUrl: 'company-profile',
  },
  PROVIDER_PROFILE_L_E: {
    routeName: 'Legal Entities',
    routeUrl: 'legal-entities',
  },
  PROVIDER_PROFILE_PARTNER: {
    routeName: 'Partners',
    routeUrl: 'contacts',
  },
  PROVIDER_PROFILE_PROJECTS: {
    routeName: 'Projects',
    routeUrl: 'projects',
  },
  PROVIDER_PROFILE_ANALYTICS: {
    routeName: 'Analytics',
    routeUrl: 'analytics',
  },
  PROVIDER_PROFILE_CONTRACTS: {
    routeName: 'Contracts',
    routeUrl: 'administrative',
  },
  PROVIDER_PROFILE_MEETINGS: {
    routeName: 'Meetings',
    routeUrl: 'meetings',
  },
  PROVIDER_PROFILE_TASKS: {
    routeName: 'Tasks',
    routeUrl: 'tasks',
  },
  PROVIDER_PROFILE_SETTINGS: {
    routeName: 'Settings',
    routeUrl: 'settings',
  },
};

export enum PROVIDER_MODULES {
  'HOME' = 'HOME',
  'PROVIDER_PROFILE' = 'PROVIDER_PROFILE',
  'LEGAL_ENTITIES' = 'LEGAL_ENTITIES',
  'LEGAL_ENTITIES_ACCOUNT' = 'LEGAL_ENTITIES_ACCOUNT',
  'LEGAL_ENTITIES_BILLING' = 'LEGAL_ENTITIES_BILLING',
  'LEGAL_ENTITIES_CONTRACTS' = 'LEGAL_ENTITIES_CONTRACTS',
  'LEGAL_ENTITIES_INVOICES' = 'LEGAL_ENTITIES_INVOICES',
  'LEGAL_ENTITIES_CLIENTS_ONBOARDING' = 'LEGAL_ENTITIES_CLIENTS_ONBOARDING',
  'USERS' = 'USERS',
  'CLIENTS' = 'CLIENTS',
  'CONTRACTS' = 'CONTRACTS',
  'CONTRACTS_LIST' = 'CONTRACTS_LIST',
  'INVOICES_AND_PAYMENTS' = 'INVOICES_AND_PAYMENTS',
  'PROJECTS' = 'PROJECTS',
  'OPPORTUNITIES' = 'OPPORTUNITIES',
  'ACCOUNTS_BILLING' = 'ACCOUNTS_BILLING',
  'TASKS' = 'TASKS',
  'TASK_ANALYTICS' = 'TASK_ANALYTICS',
  'ALL_TASK' = 'ALL_TASK',
  'MY_TASK' = 'MY_TASK',
  'ADMIN' = 'ADMIN',
  'USER_INVITE_REQUESTS' = 'USER_INVITE_REQUESTS',
  'USER_NAV' = 'USERS_NAV',
  'CUSTOMISATION' = 'CUSTOMISATION',
  'INVITE_USER' = 'INVITE_USER',
  'HELP_CENTER' = 'HELP_CENTER',
  'LOG_OUT' = 'LOG_OUT',
  'ADD_TASK' = 'ADD_TASK',
  'MESSAGES' = 'MESSAGES',
  'NOTIFICATIONS' = 'NOTIFICATIONS',
}

export const ClientRoutes = {
  HOME: '/dashboard',
  // DEMAND_MANAGEMENT,
  STRATEGIC_PRIORITIES: '/dashboard/pipeline-management/strategic-priorities',
  PIPELINE: '/dashboard/pipeline-management/pipeline',
  APPROVAL_WORKFLOWS: '/dashboard/pipeline-management/approval-workflows',
  DEMANDS: '/dashboard/projects/demands',

  // PROJECTScompRoutes
  PROJECT_LIST: '/dashboard/projects',
  PROJECT_ANALYTICS: '/dashboard/projects/project-analytics',

  // CONTRACTS
  CONTRACTS_LIST: '/dashboard/administrative',
  INVOICES_AND_PAYMENTS: '/dashboard/administrative/invoices-and-payments',

  // PANEL_MANAGEMENT
  PROVIDER_PANEL: '/dashboard/provider',
  LEGAL_ENTITIES: '/dashboard/panel-management/on-boarding',
  PANEL_ANALYTICS: '/dashboard/panel-management/panel-analytics',
  C_G_N: '/dashboard/panel-management/consource-global-network',
  SUGGESTED_PROVIDER: '/dashboard/panel-management/suggested-provider',
  
  // KNOWLEDGE_CENTER
  LIBRARY: '/dashboard/knowledge-center/library',
  SCS_ACADEMY: '/dashboard/knowledge-center/scs-academy',
  COMMUNITY: '/dashboard/knowledge-center/community',
  
  // CATEGORY_MANAGEMENT
  COMPANY_ANALYTICS: '/dashboard/category-management/company-analytics',
  OBJECTIVES: '/dashboard/category-management/objectives',
  PROCESS_ANALYTICS: '/dashboard/category-management/process-analytics',
  CONSULTING_BUDGETS: '/dashboard/category-management/consulting-budgets',
  PERFORMANCE_MANAGEMENT: '/dashboard/category-management/performance-management',
  RISK_MANAGEMENT: '/dashboard/category-management/risk-management',

  // TASKS,
  TASK_ANALYTICS: '/dashboard/tasks/analytics',
  ALL_TASK: '/dashboard/tasks/all-tasks',
  MY_TASK: '/dashboard/tasks/my-tasks',

  // ADMIN: '',
  USER_LIST: '/dashboard/users',
  USER_INVITE_REQUESTS: '/dashboard/users/invite-requests',
  ACCOUNT_BILLING: '/dashboard/account-info/accounts-billing',
  ACTIVITY: '/dashboard/account-info/activity',

  // SETTINGS: '',
  CUSTOMISATION: '/dashboard/otherSettings/client-settings',
  CUSTOMISATION_TAXONOMY: '/dashboard/otherSettings/client-demographics/demographics',
  CUSTOMISATION_MSA_LAYOUT: '/dashboard/otherSettings/msa?libraryType=Layout',
  CUSTOMIZATION_MSA_TEMPLATE: '/dashboard/otherSettings/msa?libraryType=Template',
  CUSTOMISATION_NDA_TEMPLATE: '/dashboard/otherSettings/nda?libraryType=Template',
  CUSTOMISATION_NDA: '/dashboard/otherSettings/nda',
  CUSTOMISATION_NDA_LAYOUT: '/dashboard/otherSettings/nda?libraryType=Layout',
  CUSTOMISATION_DEMAND: '/dashboard/otherSettings/demand/template/add',
  CUSTOMISATION_DEMAND_TAXONOMY: '/dashboard/otherSettings/demand/taxonomy',
  CUSTOMISATION_RFP_SETTINGS: '/dashboard/otherSettings/rfp?tab=settings',
  CUSTOMISATION_RFP_LAYOUT: '/dashboard/otherSettings/rfp?libraryType=Layout',
  CUSTOMISATION_RFP_TEMPLATE: '/dashboard/otherSettings/rfp?libraryType=Template',
  CUSTOMISATION_SOW_LAYOUT: '/dashboard/otherSettings/sow?libraryType=Layout',
  CUSTOMISATION_SOW_TEMPALTE: '/dashboard/otherSettings/sow?libraryType=Template',
  CUSTOMISATION_PERFORMANCE: '/dashboard/otherSettings/performance/survey',
  CUSTOMISATION_PERFORMANCE_IMPACT: '/dashboard/otherSettings/performance/impact',
  CUSTOMISATION_SYSTEM_SETTINGS: '/dashboard/otherSettings/system-settings/file-upload',
  CUSTOMISATION_QUESTIONNAIRES: '/dashboard/otherSettings/legal-entity/template',
  CUSTOMISATION_RISK: '/dashboard/otherSettings/risk',
  CUSTOMISATION_CLEAR_DATA: '/dashboard/otherSettings/clear-data',

  // BottomMenu
  INVITE_USER: () => { },
  HELP_CENTER: () => { },
  LOG_OUT: () => { },

  // Other
  PROVIDER_PROFILES: '',
  PROVIDER_PROFILE_COMPANY_PROFILE: '',
  PROVIDER_PROFILE_L_E: '',
  PROVIDER_PROFILE_PARTNER: '',
  PROVIDER_PROFILE_PROJECTS: '',
  PROVIDER_PROFILE_ANALYTICS: '',
  PROVIDER_PROFILE_CONTRACTS: '',
  PROVIDER_PROFILE_MEETINGS: '',
  PROVIDER_PROFILE_TASKS: '',
  PROVIDER_PROFILE_SETTINGS: '',
  ADD_TASK: '',
  MESSAGES: '',
  NOTIFICATIONS: '',
};

export const ProviderRoute = {
  HOME: '/invitation-dashboard',
  PROVIDER_PROFILE: '/invitation-dashboard/firm-profile',
  LEGAL_ENTITIES: '/invitation-dashboard/legal-entity',
  USERS: '/invitation-dashboard/users',
  CLIENTS: '/invitation-dashboard/clients',

  // CONTRACTS
  CONTRACTS_LIST: '/invitation-dashboard/administrative/list',
  INVOICES_AND_PAYMENTS: '/invitation-dashboard/administrative/invoices-and-payments',

  PROJECTS: '/invitation-dashboard/projects',
  OPPORTUNITIES: '/invitation-dashboard/opportunities',
  ACCOUNTS_BILLING: '/invitation-dashboard/account-info/accounts-billing',

  // TASKS
  TASK_ANALYTICS: '/invitation-dashboard/analytics',
  ALL_TASK: '/invitation-dashboard/all-tasks',
  MY_TASK: '/invitation-dashboard/my-tasks',

  // ADMIN: '',
  USER_INVITE_REQUESTS: '/invitation-dashboard/users/invite-requests',
  CUSTOMISATION: '/invitation-dashboard/customization/provider-details',

  CLIENT_PANEL: {
    CP_LEGAL_ENTITIES: 'legal-entities',
    CP_OPPORTUNITIES: 'opportunities',
    CP_PROJECTS: 'projects',
    CP_CONTRACTS: 'contracts',
    CP_MEETINGS: 'meetings',
    CP_TASKS: 'tasks',
  },

  // BottomMenu
  INVITE_USER: () => { },
  HELP_CENTER: () => { },
  LOG_OUT: () => { },

  // other
  ADD_TASK: '',
  MESSAGES: '',
  NOTIFICATIONS: '',
  LEGAL_ENTITIES_ACCOUNT: '',
  LEGAL_ENTITIES_BILLING: '',
  LEGAL_ENTITIES_CONTRACTS: '',
  LEGAL_ENTITIES_INVOICES: '',
  LEGAL_ENTITIES_CLIENTS_ONBOARDING: '',
};

export enum COMPANY_PROFILE_TAB {
  COMPANY_INFO = 'COMPANY_INFO',
  COMPANY_SETTINGS = 'COMPANY_SETTINGS',
}

export const kysQuestionTable = [
  { columnDef: 'questionnaireName', header: 'QUESTIONNAIRE', cssSelector: 'element-name', sort: false, key: 'templateName', type: 'text', tip: '' },
  { columnDef: 'validationProgress', header: 'VALIDATION PROGRESS', cssSelector: '', sort: false, key: 'progress', type: 'text', tip: '' },
  { columnDef: 'kysStatus', header: 'STATUS', cssSelector: '', sort: false, type: 'kysApprovalStatus', key: 'status', tip: '' },
  { columnDef: 'viewQuestionnaire', header: 'ACTION', cssSelector: 'view', sort: false, type: 'view', key: '_id', tip: 'View Questionnaire' },
];

export const kysQuestionTableForClient = [
  { columnDef: 'questionnaireName', header: 'QUESTIONNAIRE', cssSelector: 'element-name', sort: false, key: 'templateName', type: 'text', tip: '' },
  { columnDef: 'viewQuestionnaire', header: 'ACTION', cssSelector: 'view', sort: false, type: 'view', key: '_id', tip: 'View Questionnaire' },
];

export const WorkFlowConditions = {
  budget: 'Budget',
  businessUnits: 'Business Unit',
  capabilities: 'Capability',
  costCenter: 'Cost Center',
  country: 'Country',
  functions: 'Function',
  industries: 'Industry',
  organisationTags: 'Organisation Tags',
  region: 'region',
};

export const SowFieldsAndPlaceHolders = {
  preliminaryInfo: {
    intro: {
      fieldName: 'Introduction & Parties',
      placeHolder: 'Please provide details about the parties entering into this agreement.',
    },
    recitals: {
      fieldName: 'Recitals',
      placeHolder: 'Please specify the reasons for setting up this agreement.',
    },
    terminology: {
      fieldName: 'Definitions',
      placeHolder: 'Please provide definitions for the terminology and acronyms used in this agreement.',
    },
  },
  projectDescription: {
    projectName: {
      fieldName: 'Project Name',
      placeHolder: 'Please provide details on Project Name',
    },
    projectContext: {
      fieldName: 'Project Context',
      placeHolder: 'Please provide details on Project Context',
    },
    projectObjective: {
      fieldName: 'Project Objectives',
      placeHolder: 'Please provide details on Project Objectives',
    },
    projectScope: {
      fieldName: 'Project Scope',
      placeHolder: 'Please provide details on Project Scope',
    },
    servicesContext: {
      fieldName: 'Expected Services',
      placeHolder: 'Please provide details on Expected Services',
    },
    expectedDeliverables: {
      fieldName: 'Expected Deliverables',
      placeHolder: 'Please provide details on Expected Deliverables',
    },
    expectedStartDate: {
      fieldName: 'Expected Start Date',
      placeHolder: 'This item is project specific and will be populated during the SOW creation.',
    },
    expectedEndDate: {
      fieldName: 'Expected End Date',
      placeHolder: 'This item is project specific and will be populated during the SOW creation.',
    },
    expectedDuration: {
      fieldName: 'Expected Duration',
      placeHolder: 'Please provide details on Expected Duration',
    },
  },
  projectManagement: {
    methodology: {
      fieldName: 'Methodology & Timeline',
      placeHolder: 'Please provide details on Methodology & Timeline',
    },
    acceptanceCriteria: {
      fieldName: 'Acceptance Criteria',
      placeHolder: 'Please provide details on Acceptance Criteria',
    },
    organization: {
      fieldName: 'Project Organization',
      placeHolder: 'Please provide details on Project Organization',
    },
    staffing: {
      fieldName: 'Staffing',
      placeHolder: 'Please provide details on Staffing',
    },
    governance: {
      fieldName: 'Project Governance',
      placeHolder: 'Please provide details on Project Governance',
    },
    language: {
      fieldName: 'Language',
      placeHolder: 'Please provide details on Language',
    },
    workLocation: {
      fieldName: 'Work Location',
      placeHolder: 'Please provide details on Work Location',
    },
    statusReports: {
      fieldName: 'Status Reports',
      placeHolder: 'Please provide details on Status Reports',
    },
    assumptions: {
      fieldName: 'Assumptions',
      placeHolder: 'Please provide details on Assumptions',
    },
  },
  termsAndConditions: {
    invoiceCurrency: {
      fieldName: 'Invoice Currency',
      placeHolder: 'Please select Invoice Currency',
    },
    maxiProjectBudget: {
      fieldName: 'Fees & Expenses',
      placeHolder: 'Please provide details on Fees & Expenses',
    },
    invoicingMilestones: {
      fieldName: 'Invoicing Milestones',
      placeHolder: 'Please provide details on Invoicing Milestones',
    },
    paymentTerms: {
      fieldName: 'Payment Terms',
      placeHolder: 'Please provide details on Payment Terms',
    },
    invoicingContact: {
      fieldName: 'Invoicing Contact',
      placeHolder: 'Please provide details about the contact for invoicing',
    },
    contractAdministration: {
      fieldName: 'Contract Administration',
      placeHolder: 'Please provide details about who will administer the contract',
    },
    detailedFee: {
      fieldName: 'Detailed Fees',
      placeHolder: 'Please provide details on Detailed Fees',
    },
    expensePrecision: {
      placeHolder: 'Please provide details on precision of Travel & Expenses',
    },
    feesPrecision: {
      placeHolder: 'Please provide details on precision of Fees',
    }
  },
};

export const ActionStatus = [0, 1];

export enum Sandbox_status {
  CLIENT = 1,
  USERS = 2,
  PROVIDERS = 3,
  PROJECTS = 4,
  SURVEYS = 5,
}

export enum NOTATION_PIPE_RESPONSE_TYPE {
  'A_&_N' = 'AMOUNT_WITH_NOTATION',
  'A' = 'AMOUNT',
  'N' = 'NOTATION'
}

export const ProjectStepRoute = {
  'home': 1,
  'demand': 2,
  'rfp': 3,
  'sourcing': 4,
  'q&a': 5,
  'selection': 6,
  'sow': 7,
  'management': 8,
  'performance': 9
}

export const firmProjectTabRoutes = ['rfp', 'q&a', 'selection', 'sow', 'management', 'performance'];

export const ProjectStepRouteKey = {
  1: 'home',
  2: 'demand',
  3: 'rfp',
  4: 'sourcing',
  5: 'q&a',
  6: 'selection',
  7: 'sow',
  8: 'management',
  9: 'performance'
}

export const ProjectInitialSubTab = {
  'home': null,
  'demand': null,
  'rfp': 'home',
  'sourcing': 'home',
  'q&a': 'home',
  'selection': 'home',
  'sow': 'home',
  'management': 'home',
  'performance': null,
}

export enum PROJECT_ROUTES {
  HOME = 'home',
  DEMAND = 'demand',
  RFP = 'rfp',
  SOURCING = 'sourcing',
  'Q_&_A' = 'q&a',
  SELECTION = 'selection',
  SOW = 'sow',
  MANAGEMENT = 'management',
  PERFORMANCE = 'performance'
}

export const DemandTabsRoute = {
  editMode: {
    home: 0,
    settings: 1,
    team: 2,
    rationale: 3,
    'other-questions': 4,
    'associated-documents': 5,
    taxonomy: 6,
    validation: 7,
  },
  viewMode: {
    settings: 0,
    team: 1,
    rationale: 2,
    'other-questions': 3,
    'associated-documents': 4,
    taxonomy: 5,
    validation: 6,
  },
};

export const DemandTabRouteKey = {
  editMode: {
    0: 'home',
    1: 'settings',
    2: 'team',
    3: 'rationale',
    4: 'other-questions',
    5: 'associated-documents',
    6: 'taxonomy',
    7: 'validation',
  },
  viewMode: { 0: 'settings', 1: 'team', 2: 'rationale', 3: 'other-questions', 4: 'associated-documents', 5: 'taxonomy', 6: 'validation' },
};

export const RFPTabRoute = {
  0: 'home',
  1: 'team',
  2: 'purpose',
  3: 'context',
  4: 'scope-deliverables',
  5: 'schedule-budget',
  6: 'other-requirement',
  7: 'proposal-content',
  8: 'rfp-process',
  9: 'associated-docs',
}

export const RFPTabRouteKey = {
  'home': 0,
  'team': 1,
  'purpose': 2,
  'context': 3,
  'scope-deliverables': 4,
  'schedule-budget': 5,
  'other-requirement': 6,
  'proposal-content': 7,
  'rfp-process': 8,
  'associated-docs': 9,
}

export const SourcingTabRoute = {
  0: 'home',
  1: 'target-profile',
  2: 'selection',
  3: 'cover-letter',
  4: 'invitation'
}

export const SourcingTabRouteKey = {
  'home': 0,
  'target-profile': 1,
  'selection': 2,
  'cover-letter': 3,
  'invitation': 4
}

export const Q_A_TabRoute = {
  0: 'home',
  1: 'briefing',
  2: 'announcements',
  3: 'q&a',
}

export const Q_A_TabRouteKey = {
  'home': 0,
  'briefing': 1,
  'announcements': 2,
  'q&a': 3,
}

export const SowTabRoute = {
  0: 'home',
  1: 'sow-team',
  2: 'preliminary-info',
  3: 'project-description',
  4: 'project-management',
  5: 'terms-conditions',
  6: 'general-provisions',
  7: 'other-provisions',
  8: 'associated-documents',
  9: 'appendix',
  10: 'execution'
}

export const SowTabRouteKey = {
  'home': 0,
  'sow-team': 1,
  'preliminary-info': 2,
  'project-description': 3,
  'project-management': 4,
  'terms-conditions': 5,
  'general-provisions': 6,
  'other-provisions': 7,
  'associated-documents': 8,
  'appendix': 9,
  'execution': 10
}

export const P_M_RouteKey = {
  teamMember: {
    0: 'home',
    1: 'project-team',
    2: 'objectives-deliverables',
    3: 'workspace',
    4: 'project-milestones',
    5: 'invoicing-payment',
    6: 'changes'
  },
  notTeamMember: {
    0: 'home',
    1: 'project-team',
    2: 'objectives-deliverables',
    3: 'workspace',
    4: 'project-milestones',
  },
  incompleteSow: { 0: 'workspace' },
};

export const P_M_Route = {
  teamMember: {
    'home': 0,
    'project-team': 1,
    'objectives-deliverables': 2,
    'workspace': 3,
    'project-milestones': 4,
    'invoicing-payment': 5,
    'changes': 6
  },
  notTeamMember: {
    'home': 0,
    'project-team': 1,
    'objectives-deliverables': 2,
    'workspace': 3,
    'project-milestones': 4,
  },
  incompleteSow: { 'workspace': 0 },
};

export const performanceTabs = ['home', 'score-card', 'evalution', 'cost-evalution', 'impact-roi', 'key-document', 'survey', 'summary'];

export enum DOCUMENT_ACTION {
  VIEW,
  DOWNLOAD
}

export enum COMPANY_ACTIVITY_SECTION {
  'ACTIVITY_METRICS_CARD' = 'ACTIVITY_METRICS_CARD',
  'ACTIVITY_METRICS_TABLE' = 'ACTIVITY_METRICS_TABLE',
  'USER_ACTIVITY' = 'USER_ACTIVITY'
}

export const signatureModes = {
  'Wet Ink Signature': 1,
  'Electronic Signature': 2
}

export enum SIGNATURE_MODES {
  WET_INK_SIGNATURE = 1,
  E_SIGNATURE = 2
}

export const ManagementTabNameFromFieldName = {
  'milestone_table': 'Project Milestones',
  'invoicing_table': 'Invoicing and Payment',
  'projectObjective': 'Objectives & Deliverables',
  'projectScope': 'Objectives & Deliverables',
  'expectedServicesContext': 'Objectives & Deliverables',
  'expectedDeliverables': 'Objectives & Deliverables'
}

export const ManagementFieldName = {
  'milestone_table': 'Project Milestones',
  'invoicing_table': 'Invoicing and Payment',
  'projectObjective': 'Project Objective(s)',
  'projectScope': 'Project Scope',
  'expectedServicesContext': 'Expected Services Context',
  'expectedDeliverables': 'Expected Deliverables'
}

export enum CHANGE_ORDER_TABS {
  'INTRODUCTION' = 'INTRODUCTION',
  'OBJECTIVES' = 'OBJECTIVES',
  'MILESTONE' = 'MILESTONE',
  'INVOICES' = 'INVOICES',
  'OTHER_CHANGES' = 'OTHER_CHANGES',
  'TRACING' = 'TRACING'
}

export enum CHANGE_ORDER_FIELDS {
  'invoicing_table' = 'INVOICES',
  'milestone_table' = 'MILESTONE',
  'projectObjective' = 'PROJECT_OBJECTIVES',
  'projectScope' = 'PROJECT_SCOPE',
  'expectedServicesContext' = 'EXPECTED_SERVICES',
  'expectedDeliverables' = 'EXPECTED_DELIVERABLES'
}

export const CALENDAR_TYPES = ['google', 'microsoft']

export const READ_ONLY_MESSAGE = "Step cannot be unlocked. If you really need to unlock - Contact your administrator"

export enum MARKET_PLACE_FILTER {
  ONLY_MARKET_PLACE='only-marketplace',
  HIDE_MARKET_PLACE='hide-marketplace'
}

export const MarketPlaceFilterOptions = [
  {
    value: 'ALL',
    label: 'All'
  },
  {
    value: MARKET_PLACE_FILTER.ONLY_MARKET_PLACE,
    label: 'Only Marketplace'
  },
  {
    value: MARKET_PLACE_FILTER.HIDE_MARKET_PLACE,
    label: 'Hide Marketplace'
  },
]
  
export const INITIATIVE_STATUS = {
'IDEA': 'Idea', 
'DEMAND': 'Demand', 
'PROJECT': 'Project', 
'CLOSED': 'Closed', 
'REJECTED': 'Rejected',
'COMPLETED': 'Completed'
}

export const INITIATIVE_TAG = {
  'IDEA': 'tag-pipeline-idea', 
  'DEMAND': 'tag-pipeline-demand', 
  'PROJECT': 'tag-pipeline-project', 
  'CLOSED': 'tag-pipeline-closed', 
  'REJECTED': 'tag-pipeline-rejected',
  'COMPLETED': 'tag-pipeline-completed'
  }

export const SETTINGS_DRAWER = {
  COLOR_DRAWER: 'COLOR_DRAWER',
}


export enum INITIATIVE_STATUS_ENUM {
  'IDEA' = 'IDEA', 
  'DEMAND' = 'DEMAND', 
  'PROJECT' = 'PROJECT', 
  'CLOSED' = 'CLOSED', 
  'REJECTED' =  'REJECTED'
  }